import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Footer from '../../components/Footer';
import ImgDesc from '../../components/ImgDesc';
import Nav from '../../components/Nav';
import phone from '../../assets/phone.png';
// import pig from '../../assets/pig.svg';
// import face from '../../assets/face-recognition.svg';
import chv from '../../assets/chv-lg.svg';
// import dashboard from "../../assets/dashboard.svg";
import styles from './index.module.scss';
import banner1 from '../../assets/banner_dark.png';
import banner2 from '../../assets/banner2_dark.png';
import banner3 from '../../assets/banner3_dark.png';
import videoAd from '../../assets/video.mp4';
import banner4 from '../../assets/banner4_dark.png';
import poster from '../../assets/adposter.jpeg';
import side1 from '../../assets/side1.png';
import side2 from '../../assets/side2.png';
import side3 from '../../assets/side3.png';
import side4 from '../../assets/side4.png';
import side5 from '../../assets/side5.png';
// import per1 from "../../assets/per1.svg";
// import per2 from "../../assets/per2.svg";
// import per3 from "../../assets/per3.svg";
// import spd from "../../assets/spd.png";
import comm from '../../assets/comm.svg';
import naija from '../../assets/naija.svg';
import handmoney from '../../assets/handMoney.svg';
// import control from '../../assets/control.svg';
// import controlgreen from '../../assets/controlgreen.svg';
// import flex from '../../assets/flex.svg';
// import flexhover from '../../assets/flex1.svg';
// import search from '../../assets/search.svg';
// import searchgreen from '../../assets/search1.svg';
// import touchid from '../../assets/touch.svg';
// import touchgreen from '../../assets/touchgreen.svg';
import ios from '../../assets/appstore.svg';
// import hold from '../../assets/hold.svg';
import android from '../../assets/playstore.jpg';
// import realestate from '../../assets/real-estate.svg';
import Dangote from '../../assets/partners/Dangote.png';
import aiico from '../../assets/partners/aiico.png';

import xclose from '../../assets/x.svg';
import FullBannerVideo from '../../components/FullBannerVideo';
// import nimc from "../../assets/partners/nimc.png";
// import nmrc from "../../assets/partners/nmrc.png";
import zenithI from '../../assets/partners/zenithinsurance.png';
import gtb from '../../assets/partners/gtb.png';
import fbn from '../../assets/partners/fbn.png';
import abb from '../../assets/partners/abb.png';
import tower from '../../assets/partners/tower.jpg';
// import lotus from "../../assets/partners/lotus.png";
// import staco from "../../assets/partners/staco.svg";
import swiss from '../../assets/partners/swiss.jpg';
import zenith from '../../assets/partners/zenith.png';
import bagi from '../../assets/partners/bagi.svg';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';

const Home = () => {
  const [modal, setModal] = useState(false);
  const [current, setCurrent] = useState(0);
  const [notice, setNotice] = useState(true);
  const banners = {
    0: {
      title: 'Why Pay To Rent, When You Can Pay To Own',
      desc:
        'Don’t settle for paying rent, own your space. Lease to own on our mobile platform. ',
      banner: banner1
    },
    1: {
      title: 'Your Freedom Is In Your Hands',
      desc:
        'Sign up and save on our mobile platform for just 15 months and get the keys to your home.',
      banner: banner3
    },
    2: {
      title: 'Access Home Finance Without The Bias',
      desc:
        'Our mobile platform determines when you move in by tracking how you save. No human bias, just tech!',
      banner: banner2
    },
    3: {
      title: 'Enjoy Flexibility With Convenience ',
      desc:
        'Our scheme is flexible enough to grow with your changing needs as you change jobs, move cities, and grow your income.',
      banner: banner4
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const nextPage = () => {
    if (current <= 2) {
      return setCurrent(current + 1);
    }
    if (current >= 3) {
      return setCurrent(0);
    }
  };
  const prevPage = () => {
    if (current > 0) {
      return setCurrent(current - 1);
    }
    if (current <= 0) {
      return setCurrent(3);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      nextPage();
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={styles.home}>
      <Nav showBackground />
      <div className={styles.main}>
        {notice && (
          <div className={styles.notice}>
            Grow equity, refer friends to First Homes and both of you can earn
            up to N500, 000 per sign up. <Link to='/bonus'> Learn More </Link>
            <img src={xclose} alt='close' onClick={() => setNotice(false)} />
          </div>
        )}
        <div
          className={styles.background}
          style={{
            background: `url(${banners[current].banner}) center center / cover no-repeat`
          }}
        >
          {/* <video src={backVideo} autoPlay muted loop /> */}
          <div className={styles.first}>
            <div className={styles.title}> {banners[current].title} </div>
            <div className={styles.desc}> {banners[current].desc} </div>
            <div className={styles.buttons}>
              <Link to='/yourhome' className={styles.dark}>
                LEARN MORE
              </Link>
              <Link to='/signup'> TRY IT NOW </Link>
            </div>
          </div>
          <div className={styles.chevs}>
            <img onClick={prevPage} src={chv} alt='' />
            <img onClick={nextPage} src={chv} alt='' />
          </div>
        </div>
      </div>
      <div className={styles.rent}>
        <div className={styles.title}> First Homes Explained </div>
        {/* <div className={styles.desc}>
          Real Estate is the guaranteed way to grow your net worth. Why not make
          your rent work for you with our lease-to-own offer where you increase
          your equity as you make your monthly payment ?
        </div> */}
      </div>
      {/* Bottom={() => (
                            <button className={styles.noBg} onClick={() => setModal(true)}>
                              <img src={playBtn} alt="play button"/>
                            </button>
                          )} */}
      <FullBannerVideo content={videoAd} poster={poster} />
      <div className={styles.trynow}>
        <Link to='/yourhome'>LEARN MORE</Link>
      </div>
      <div className={styles.achievegroup}>
        <div className={styles.box1}>
          <div>
            <img src={comm} alt='' />
            <div>
              <b> 60, 000 </b> apartments in 4 years
            </div>
          </div>
        </div>
        <div className={styles.box2}>
          <div>
            <img src={handmoney} alt='' />
            <div> $3 billion worth of investments </div>
          </div>
        </div>
        <div className={styles.box3}>
          <div>
            <img src={naija} alt='' />
            <div> Available in 36 states of the Nigerian Federation </div>
          </div>
        </div>
      </div>

      <div className={styles.partnerdivide}> Partners </div>
      <div className={styles.partners}>
        <div className={styles.desc}>
          Our ecosystem is powered by trusted corporate partners
        </div>
        <div className={styles.logos}>
          <img src={zenith} alt='zenith' />
          <img src={Dangote} alt='Dangote' />
          <img src={fbn} alt='fbn' />
          <img src={bagi} alt='bagi' />
          <img src={gtb} alt='gtb' />
          <img src={zenithI} alt='zenithinsuarance' />
          <img src={swiss} alt='swissdoor' />
          <img src={aiico} alt='aiico' />
          <img src={abb} alt='abb' />
          <img src={tower} alt='tower' />
          {/* <img src={lotus} alt="lotus" />
          <img src={nmrc} alt="nmrc" />
          <img src={nimc} alt="nimc" />
          <img src={staco} alt="staco" />
          <img src={sterling} alt="sterling" />
          */}
        </div>
      </div>

      <div className={styles.hint}> More benefits for First Homes users </div>
      <div className={styles.pad}>
        <ImgDesc
          title="Move from one property to another"
          image={side1}
          desc="Work transfers you to another city? Not to worry, just pack up your personal effects plus equity and settle into a similar First Homes apartment in your new city (subject to availability- T&C Applies)"
        />
        <ImgDesc
          right
          title="Wealth creation"
          image={side2}
          desc="Not only do we provide housing solutions, we also help you with financial tips to improve your creditworthiness and set you on the path to financial freedom."
        />
        <ImgDesc
          title="Insurance cover for all our members"
          image={side3}
          desc="Sign up to a tailor-made insurance policy that covers your monthly payment for 6 months should you lose your job or unable to work due to personal injury. And in the unfortunate event of loss of life, the apartment is paid up in full and the ownership is transferred to your next of kin."
        />
        <ImgDesc
          right
          sub="Blockwise 101"
          title="Financial Literacy "
          image={side4}
          desc="Financial education is the key to wealth creation. A basic but sound knowledge of budgeting, interest rates, debts and equity will most certainly improve your investment decision."
        />
        <ImgDesc
          title="Community"
          image={side5}
          desc="We create communities of people with shared preferences and aspirations which promote collaborations, connections, knowledge sharing and support."
        />
      </div>

      <div className={styles.feedback}>
        <div> Pre - launch Feedback </div>
        <div className={styles.feedbacks}>
          <div className={styles.card}>
            <div>
              {/* <img src={per1} alt="avatar" /> */}
              <p>
                “Wow! I love the proposition, when will I be able to download the
                app and start our journey to freedom ? It has been really
                painful before you guys came along.”
              </p>
            </div>
            <div className={styles.bio}>
              <div className={styles.author}> Edward Akintayo </div>
              <div className={styles.location}> Lagos </div>
            </div>
          </div>
          <div className={styles.card}>
            <div>
              {/* <img src={per2} alt="avatar" /> */}
              <p>
                “I cannot wait to get the app and enjoy its benefits, I wish
                First Homes was available earlier. It will be a great relief.”
              </p>
            </div>
            <div className={styles.bio}>
              <div className={styles.author}> Dr Reuben Azubuike </div>
              <div className={styles.location}> Port - Harcourt </div>
            </div>
          </div>
          <div className={styles.card}>
            <div>
              {/* <img src={per3} alt="avatar" /> */}
              <p>
                “The benefits are far reaching especially the transparent system
                which removes bias from loan application. It’ s a breath of
                fresh air in the home ownership space. It will be a game
                changer.”
              </p>
            </div>
            <div className={styles.bio}>
              <div className={styles.author}> Danladi Abubakar </div>
              <div className={styles.location}> Kano </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.appstore} id='appDownload'>
        <div>
          <img className={styles.ph} src={phone} alt='phone' />
        </div>
        <div className={styles.side}>
          <div className={styles.head}> Download our free mobile app </div>
          {/* <div className={styles.desc}> Coming soon on iOS and Android </div> */}
          <div className={styles.desc}> Available for Android. </div>

          <div className={styles.buttons}>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://apps.apple.com/us/app/firsthome/id1483331420?ls=1'
            >
              <img src={ios} alt='ios' />
            </a>
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://play.google.com/store/apps/details?id=com.firsthome'
            >
              <img src={android} alt='android' />
            </a>
          </div>
        </div>
      </div>

      <ScrollUpButton
        ToggledStyle={{ bottom: 100 }}
        style={{ borderColor: '#174a41' }}
      />
      <Footer />
      {modal && (
        <div className={styles.overall}>
          <div className={styles.card}>
            <video controls src={videoAd} />
            <button className={styles.linkbtn} onClick={() => setModal(false)}>
              EXIT
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
