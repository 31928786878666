import React, { createRef } from 'react';

import Footer from '../../components/Footer';
import banner from '../../assets/career_dark.png';

import Nav from '../../components/Nav';
import styles from './index.module.scss';
import Accordian from '../../components/Accordian';
import { Link } from 'react-router-dom';

const Career = () => {
  let ref = createRef();
  const openings = [
    {
      title: 'DATA SCIENTIST',
      body: (
        <div className={styles.box}>
          <div className={styles.right}>
            <Link to={`/careers/apply?role=${'DATA SCIENTIST'}`}>
              Apply now
            </Link>
          </div>
          <div className={styles.writeup}>
            <h3>Key Roles and Responsibilities</h3>
            <p>
              <h4>Analytical Roles</h4>
              To carry analytical role of designing, implementing and evaluating
              advanced statistical models and approaches for application in the
              business’s most complex issues through researching new ways for
              predicting and modeling end-user behavior as well as investigating
              data summarization and visualization techniques for conveying key
              applied analytics findings To performs ad-hoc data mining and
              exploratory statistics tasks on very large data-sets related to
              the business’s strategies. Preparing reports and presentations for
              senior managers and relevant stakeholders that will give insights
              for departmental as well as business wide decision making. The
              Data Scientist plays a strategic role in the development of new
              approaches to understand the business’s consumer trends and
              behaviors as well as approaches to solve complex business issues,
              for example, the optimization of product performance and gross
              profit.
            </p>
            <p>
              <h4>Managerial Roles</h4>
              The candidate shall be playing a minor managerial role where
              he/she aids in the building of the foundation of state-of-the-art
              scientific and technical capabilities within the Data and
              Analytics department in order to support several planned and
              ongoing data analytics projects. To constantly stay on top of the
              industry’s trends, in order to provide forward-thinking
              recommendations to the business. Strive to build an in-depth
              understanding of the problem domain and available business data
              assets, especially those pertaining to strategic initiatives and
              value-based programs. Identifying the data the business should be
              collecting, devises methods of instrumenting the business’s system
              in order to extract this information and work with other data and
              analytics departments to develop the processes that transform raw
              data into actionable business insights. To mentor supporting
              personnel for this position, continuously ensuring effective
              execution of duties at this junior level.
            </p>
            <p>
              <h4>Collaboration</h4>
              Collaborating with senior data scientists and Managers to
              communicate obstacles and findings to relevant stakeholders in an
              effort to improve decision making and drive business performance.
              Generating illustrations and visualizations of data that can
              easily be comprehended and simplified for non-technical
              stakeholder audiences, communicating statistical modeling results
              as measures of the business’s impact. To work closely with other
              data analytics team, data warehouse engineers, data engineers,
              product managers, the IT department, and other informatics
              analysts across the business in solving complex business issuesg
              effective execution of duties at this junior level.
            </p>
            <br />
            <h3>Required Qualifications </h3>
            <p>
              <h4>Education</h4>
              The Data Scientist has to have a bachelor’s degree in Statistics,
              Mathematics, Computer Science, Machine Learning, Economics, or any
              other related quantitative field. Working experience of the
              equivalent is also acceptable for this position.
            </p>
            <p>
              <h4>Experience</h4>
              <ul>
                <li>
                  A candidate for this position must have had at least 3 years
                  of working experience working with business
                  analysis/informatics and business outcomes research within a
                  fast-paced and complex business setting, preferably working as
                  support data scientist junior support personnel.
                </li>
                <li>
                  The candidate will also have experience working in probability
                  and statistics, time-series analysis, or econometrics as well
                  as experience in the use of machine learning methods, for
                  example, linear regression, correlation, statistical
                  significance, and so forth.
                </li>
                <li>
                  A candidate for this position will also require strong
                  programming skills and experience working with tools such as
                  SAS, R Programming, Open Source, visualizations, and so forth.
                </li>
                <li>
                  A suitable candidate will also have had experience as well as
                  in-depth knowledge of the Python programming language, SAS
                  Enterprise Miner and substantial knowledge of big data
                  platforms such as Aster and Hadoop.
                </li>
                <li>
                  Communication skills for the Data Scientist, both in written
                  and verbal form are a must have.
                </li>
                <li>
                  A suitable candidate will demonstrate proficiency in the use
                  of Ms Word, Ms Excel, PowerPoint, and Outlook, which will be
                  necessary for the creation of both visually and verbally
                  engaging reports and presentations, for senior data scientists
                  and key stakeholders. He/ She must also have demonstrated
                  skills in SQL server reporting services, Salesforce, analysis
                  services, integration services, Tableau, or other data
                  visualization tools
                </li>
              </ul>
            </p>
          </div>
        </div>
      )
    },
    {
      title: 'SOCIAL MEDIA EXPERT',
      body: (
        <div className={styles.box}>
          <div className={styles.right}>
            <Link to={`/careers/apply?role=${'SOCIAL MEDIA EXPERT'}`}>
              Apply now
            </Link>
          </div>
          <div className={styles.writeup}>
            <h3>Key Roles and Responsibilities</h3>
            <p>
              <h4>General Roles</h4>
              The candidate will be charged with managing a number of client’s
              social media profiles and executing the marketing strategy. The
              successful candidate will develop and maintain but not limited to
              blogs, video feeds, trending social networking sites and resource
              centers. The candidate will be responsible for providing the
              customers with comprehensive analytics, data presentations, and
              resource management reports. This candidate will have a
              demonstrated history of working in a fast-paced environment while
              delivering multiple projects on schedule, on budget and with no
              errors.
            </p>
            <p>
              <h4>Specific Duties & Responsibilities</h4>
              <ul>
                <li>
                  Implement social media strategies for multiple companies that
                  build brand awareness, generate inbound traffic and product
                  adoption.{' '}
                </li>
                <li>Creation of content which meets our customer standards </li>
                <li>Develop and deliver social media optimization (SMO). </li>
                <li>
                  Stay current with social media trends and best practices.{' '}
                </li>
                <li>
                  Research opportunities for new social marketing platforms and
                  select adapt current process to fit client needs.
                </li>
                <li>Review and approve content on a daily basis. </li>
                <li>Creating and managing monthly promotions</li>
              </ul>
            </p>

            <br />
            <h3>Required Qualifications </h3>
            <p>
              <h4>Experience</h4>
              <ul>
                <li>Strong project management or organizational skills</li>
                <li>
                  In-depth knowledge and understanding of social media platforms
                  and their respective participants (Facebook, Youtube, Twitter,
                  Flickr etc.) and how they can be deployed.
                </li>
                <li>
                  Ability to effectively communicate information and ideas in
                  written and verbal format.
                </li>
                <li>Ability to build and maintain client relationships.</li>
                <li>
                  Team player, with the confidence to take the lead and guide
                  other departments when necessary.
                </li>
                <li>
                  Good technical understanding and can learn new hardware and
                  software quickly.
                </li>
                <li>
                  Public relations, marketing, sales, community management
                  experience
                </li>
              </ul>
            </p>
            <p>
              <h4>Education</h4>
              Bachelor's Degree, preferably in Communications/English, Public
              Relations, Marketing, Journalism or Design, Political Science.
              Will consider lower level of education based on passion for the
              industry.
            </p>
          </div>
        </div>
      )
    },
    {
      title: 'TELEMARKETING REPRESENTATIVE',
      body: (
        <div className={styles.box}>
          <div className={styles.right}>
            <Link to={`/careers/apply?role=${'TELEMARKETING REPRESENTATIVE'}`}>
              Apply now
            </Link>
          </div>
          <div className={styles.writeup}>
            <p>
              <h4>Duties</h4>
              <ul>
                <li>
                  Advises present or prospective customers by answering incoming
                  calls on a rotating basis; operating telephone equipment,
                  automatic dialing systems, and other telecommunications
                  technologies. Influences customers to buy or retain product or
                  service by following a prepared script to give product
                  reference information.
                </li>
                <li>
                  Documents transactions by completing forms and record logs.
                </li>
                <li>
                  Maintains database by entering, verifying, and backing up
                  data.
                </li>
                <li>
                  Keeps equipment operational by following manufacturer's
                  instructions and established procedures; notifying team leader
                  of needed repairs.
                </li>
                <li>
                  Maintains operations by following policies and procedures;
                  reporting needed changes.
                </li>
                <li>
                  Maintains quality service by following organization standards.
                </li>
                <li>
                  Maintains technical knowledge by attending educational
                  workshops; reviewing publications.
                </li>
                <li>
                  Contributes to team effort by accomplishing related results as
                  needed.
                </li>
              </ul>
            </p>

            <br />
            <p>
              <h4>Required Skills and Qualifications</h4>
              Persuasion, Telephone Sales, Customer Focus, Data Entry Skills,
              Selling to Customer Needs, Closing Skills, Telephone Skills,
              Organization, Energy Level, Persistence, Product Knowledge
            </p>
          </div>
        </div>
      )
    },
    {
      title: 'FINANCE MANAGER',
      body: (
        <div className={styles.box}>
          <div className={styles.right}>
            <Link to={`/careers/apply?role=${'FINANCE MANAGER'}`}>
              Apply now
            </Link>
          </div>
          <div className={styles.writeup}>
            <p>
              <h4>Position Description and General Roles</h4>
              By reporting to the Director of Finance/ MD, the Finance Manager
              leads all day-to-day accounting operations, with functional
              responsibility for accounting, accounts payable, payroll, and
              grants administration. Responsibilities also include production of
              financial reports; proper maintenance of accounting records;
              accurate processing of financial transactions; and administration
              of a comprehensive set of controls and budgets designed to
              mitigate risk, enhance the accuracy of the company’s reported
              financial results, and ensure that reported results comply with
              generally accepted accounting principles (GAAP), adhere to grants
              management standards, and result in flawless audits. The Finance
              Manager supervises the Accounting and HR Associate in the areas of
              benefits administration, new-hire onboarding, and other related
              functions.
            </p>

            <p>
              <h4> Transactional Responsibilitie</h4>
              Ensure that all financial transactions are processed accurately,
              in a timely manner, and in accordance with generally accepted
              accounting principles.
              <ul>
                <li>
                  Develop, implement, and maintain processes and controls that
                  are current best practices related to transaction processing.{' '}
                </li>
                <li>
                  Manage, oversee, process (as appropriate), and act as backup
                  for processing all of the following transactions: payroll,
                  accounts payable, revenue and expense, fixed assets, cash,
                  bank reconciliations, and allocations.{' '}
                </li>
                <li>
                  Perform month-end closing procedures, including overhead
                  allocations, account reconciliations, and updating schedules.{' '}
                </li>
                <li>Maintain an orderly accounting filing system. </li>
              </ul>
            </p>

            <p>
              <h4> Reporting Responsibilities</h4>
              <ul>
                <li>
                  Assist in issuing timely, accurate, and complete financial
                  statements for all levels, including Board of Directors,
                  executive, and management.
                </li>
                <li>
                  Assist in coordinating the preparation of the draft audited
                  financial statements and all tax returns.
                </li>
                <li>
                  Coordinate the preparation of financial information in the
                  corporate annual report.
                </li>
                <li>
                  Recommend and report upon benchmarks against which to measure
                  organizational performance.{' '}
                </li>
                <li>Calculate and issue financial and operating metrics. </li>
                <li>
                  Assist in production of cash flow reports, annual budget, and
                  forecasts.
                </li>
                <li>
                  Calculate variances from the budget and report significant
                  issues to management.{' '}
                </li>
                <li>Provide for a system of management cost reports.</li>
                <li>
                  Provide financial analyses and models as needed, in particular
                  for capital investments, pricing decisions, and contract
                  negotiations.{' '}
                </li>
              </ul>
            </p>
            <p>
              <h4> Compliance Responsibilities</h4>
              <ul>
                <li>
                  Prepare and assist with developing audit schedules for the
                  annual audit.
                </li>
                <li>
                  Suggest improvements in processes to increase organizational
                  effectiveness.
                </li>
                <li>
                  Gather 990 and 990T information for the annual tax return.
                </li>
                <li>Oversee state registration filings.</li>
              </ul>
            </p>
            <p>
              <h4>Knowledge, Skills, and Abilities</h4>
              <ul>
                <li>
                  Proactive, hands-on manager who will own, in partnership with
                  the Finance Director, responsibility for the Finance
                  Department.
                </li>
                <li>
                  Strategic thinker who possesses solid business acumen and is
                  able to organize and manage multiple priorities.
                </li>
                <li>
                  Exceptional verbal and written communications skills and the
                  ability to interact effectively with others, both internally
                  and externally.
                </li>
                <li>
                  Excellent time management skills, with the ability to perform
                  multiple tasks and meet critical deadlines while maintaining
                  accuracy and quality.{' '}
                </li>
                <li>
                  Proven ability to make complex and time-sensitive decisions in
                  the best interests of the organization.{' '}
                </li>
              </ul>
            </p>
            <p>
              <h4>Qualifications</h4>
              <ul>
                <li>
                  Bachelor’s degree in accounting or finance. CPA is preferred
                  but not required.
                </li>
                <li>
                  Minimum of 5 years of finance and accounting experience,
                  including finance, accounting, audit, and analysis (including
                  gathering, evaluating, presenting, and reporting financial
                  information to management and external stakeholders).
                </li>
                <li>
                  Solid experience in coordinating audit activities and managing
                  reporting, budget development and analysis, accounts payable
                  and receivable, general ledger, payroll, and accounting for
                  investments.
                </li>
                <li>
                  Nonprofit experience, with experience with fund/grants
                  accounting preferred.
                </li>
                <li>
                  Proficiency with Intacct or other accounting software and
                  excellent Excel skills.
                </li>
              </ul>
            </p>
          </div>
        </div>
      )
    },
    {
      title: 'SOFTWARE DEVELOPER',
      body: (
        <div className={styles.box}>
          <div className={styles.right}>
            <Link to={`/careers/apply?role=${'SOFTWARE DEVELOPER'}`}>
              Apply now
            </Link>
          </div>
          <div className={styles.writeup}>
            <p>
              <h4>Responsibilities</h4>

              <ul>
                <li>Develop, test and implement new software programs</li>
                <li>
                  Clearly and regularly communicate with management and
                  technical support colleagues
                </li>
                <li>
                  Design and update software database. This includes, but is not
                  limited to: Software Applications, Web Sites, Data
                  Communication Processes and User Interfaces
                </li>
                <li>
                  Test, maintain and recommend software improvements to ensure
                  strong functionality and optimization
                </li>
                <li>
                  Independently install, customize and integrate commercial
                  software packages
                </li>
                <li>Facilitate root cause analysis of system issues</li>
                <li>
                  Work with experienced team members to conduct root cause
                  analysis of issues, review new and existing code and/or
                  perform unit testing{' '}
                </li>
                <li>
                  Identify ideas to improve system performance and impact
                  availability{' '}
                </li>
                <li>Resolve complex technical design issues </li>
                <li>Development of technical specifications and plans </li>
                <li>
                  Analyze user requirements and convert requirements to design
                  documents{' '}
                </li>
                <li>
                  Make good technical decisions that provide solutions to
                  business challenges{' '}
                </li>
                <li>
                  Provide comprehensive support to internal customers; achieve
                  resolution to outstanding problems or issues{' '}
                </li>
                <li>
                  Compile timely, comprehensive and accurate documentation and
                  or reports as requested{' '}
                </li>
                <li>
                  Possess up-to-date knowledge of technological developments in
                  the industry{' '}
                </li>
                <li>
                  Communicate effectively and professionally in all forms of
                  communication with internal and external customers{' '}
                </li>
                <li>
                  Possess strong problem solving and decision making skills
                  while using good judgment{' '}
                </li>
                <li>
                  Multi-task and change from one task to another without loss of
                  efficiency or composure
                </li>
              </ul>
            </p>

            <p>
              <h4> Software Developer top skills & proficiencies</h4>
              <ul>
                <li>Expertise in current computer hardware and software</li>
                <li>
                  Ability to use one or more development language (C++, PHP,
                  HTML, etc.)
                </li>
                <li>Strong communication skills</li>
                <li>Ability to work in a team</li>
                <li>Eye for detail and identifying problems</li>
                <li>An understanding of business</li>
                <li>Analytical and commercial experience</li>
              </ul>
            </p>
            <p>
              <h4>Education</h4>A degree and a technical background should be
              required in one of the following subjects: Computer Science,
              Computer Software Engineering, Software Programming and
              Development, Mathematics, Electronics or Physics.
            </p>
          </div>
        </div>
      )
    }
  ];
  return (
    <div className={styles.career}>
      <Nav showBackground />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            background: `url(${banner})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className={styles.detail}>
            Careers at First Homes
            <p>
              At First Homes, we dream, we dare to do, we value experience, we
              learn together. If this resonates with you, we will like to hear
              from you...
            </p>
            <button
              onClick={() =>
                ref.current.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start'
                })
              }
            >
              VIEW JOB OPENINGS
            </button>
          </div>
        </div>

        <div className={styles.content} ref={ref}>
          <div className={styles.top}>JOIN OUR TEAM</div>

          <div className={styles.openings}>
            {openings.map((opening, i) => (
              <Accordian
                key={i}
                title={opening.title}
                body={opening.body}
                last={i === openings.length - 1}
              />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Career;
