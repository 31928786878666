import React, { useState } from 'react';

// import bd from '../../assets/bd.svg';
// import ba from '../../assets/ba.svg';
// import sq from '../../assets/sq.svg';
import gallery1 from '../../assets/gallery/gallery1.jpeg';
import gallery2 from '../../assets/gallery/gallery2.jpeg';
import gallery3 from '../../assets/gallery/gallery3.jpeg';
import gallery4 from '../../assets/gallery/gallery4.jpeg';
import gallery5 from '../../assets/gallery/gallery5.jpeg';
import gallery6 from '../../assets/gallery/gallery6.jpeg';
import gallery7 from '../../assets/gallery/gallery7.jpeg';
import gallery8 from '../../assets/gallery/gallery8.jpeg';
import gallery9 from '../../assets/gallery/gallery9.jpeg';
import gallery10 from '../../assets/gallery/gallery10.jpeg';
import gallery11 from '../../assets/gallery/gallery11.jpeg';
import gallery12 from '../../assets/gallery/gallery12.jpeg';
import gallery13 from '../../assets/gallery/gallery13.jpeg';
import gallery14 from '../../assets/gallery/gallery14.jpeg';
import gallery15 from '../../assets/gallery/gallery15.jpeg';
import gallery16 from '../../assets/gallery/gallery16.jpeg';
import gallery17 from '../../assets/gallery/gallery17.jpeg';
import gallery18 from '../../assets/gallery/gallery18.jpeg';
import gallery19 from '../../assets/gallery/gallery19.jpeg';
import gallery20 from '../../assets/gallery/gallery20.jpeg';
import gallery21 from '../../assets/gallery/gallery21.jpeg';
import gallery22 from '../../assets/gallery/gallery22.jpeg';
import gallery23 from '../../assets/gallery/gallery23.jpeg';
import gallery24 from '../../assets/gallery/gallery24.jpeg';

import styles from './index.module.scss';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import close from '../../assets/exit2.svg';
import currency from '../../utils/currency';
// import { useMappedState, useDispatch } from 'redux-react-hook';
// import { StackerSlide, StackerSlider } from 'react-stacker';
import ImageGallery from 'react-image-gallery';

const Gallery = () => {
  const [selected, setHouse] = useState({});
  const [show, setShow] = useState(false);

  const housesArr = [
    {
      type: 'Studio Apartment',
      cost: 7200000,
      location: 'Sangotedo',
      address: 'Lekki Pearl Estate',
      state: 'Lagos'
    },
    {
      type: 'One Bedroom Apartment',
      cost: 8000000,
      location: 'Sangotedo',
      address: 'Lekki Pearl Estate',
      state: 'Lagos'
    },
    {
      type: 'Two Bedroom Apartment',
      cost: 11200000,
      location: 'Sangotedo',
      address: 'Lekki Pearl Estate',
      state: 'Lagos'
    },
    {
      type: 'Studio Apartment',
      cost: 9200000,
      location: 'Abijo',
      address: 'Green Park Estate',
      state: 'Lagos'
    },
    {
      type: 'One Bedroom Apartment',
      cost: 10200000,
      location: 'Abijo',
      address: 'Green Park Estate',
      state: 'Lagos'
    },
    {
      type: 'Two Bedroom Apartment',
      cost: 14200000,
      location: 'Abijo',
      address: 'Green Park Estate',
      state: 'Lagos'
    }
  ];
  // const SlideEntry = ({ src }) => (
  //   <div
  //     style={{
  //       width: '100%',
  //       height: '100%',
  //       backgroundImage: `url(${src})`,
  //       backgroundSize: 'cover',
  //       backgroundRepeat: 'no-repeat',
  //       backgroundPosition: 'center',
  //       display: 'flex',
  //       justifyContent: 'flex-start',
  //       alignItems: 'flex-end',
  //       padding: '50px 30px',
  //       boxSizing: ' border-box',
  //       color: '#fff',
  //       fontSize: '16px',
  //       lineHeight: '1.5',
  //       fontWeight: '300',
  //       letterSpacing: '3px'
  //     }}
  //   />
  // );
  // const allHouses = [
  //   {
  //     images: [
  //       gallery1,
  //       gallery2,
  //       gallery3,
  //       gallery4,
  //       gallery1,
  //       gallery2,
  //       gallery3,
  //       gallery4
  //     ],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [
  //       gallery1,
  //       gallery2,
  //       gallery3,
  //       gallery4,
  //       gallery1,
  //       gallery2,
  //       gallery3,
  //       gallery4
  //     ],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   },
  //   {
  //     images: [gallery1, gallery2, gallery3, gallery4],
  //     price: 20000000,
  //     bd: 3,
  //     ba: 3,
  //     sq: 1232,
  //     add: '1842 E Windmere Dr Phoenix, AZ 85048 Ahwatukee Foothills'
  //   }
  // ];

  // const House = ({ house, index }) => (
  //   <div
  //     className={styles.card}
  //     onClick={() => {
  //       setHouse(house);
  //       setShow(true);
  //     }}
  //   >
  //     <div className={styles.img}>
  //       <img src={house['images'][0]} alt='banner' />
  //     </div>
  //     <div className={styles.header}>
  //       <div className={styles.title}>₦ {currency(house['price'], true)}</div>
  //       <div className={styles.time}>Available</div>
  //     </div>
  //     <div className={styles.set}>
  //       <div className={styles.setum}>
  //         <img alt='type' src={bd} /> {house['bd']} Beds
  //       </div>
  //       <div className={styles.setum}>
  //         <img alt='type' src={ba} /> {house['ba']} Baths
  //       </div>
  //       <div className={styles.setum}>
  //         <img alt='type' src={sq} />
  //         {currency(house['sq'], true)} sqft
  //       </div>
  //     </div>
  //     <div className={styles.desc}>{house['add']}</div>
  //   </div>
  // );

  const Modal = () => {
    const [ind, setInd] = useState(0);
    return (
      <div className={styles.modal}>
        <div className={styles.over} onClick={() => setShow(false)}></div>
        <div className={styles.content}>
          <div className={styles.close} onClick={() => setShow(false)}>
            <img src={close} alt='' />
          </div>
          <div className={styles.left}>
            <div className={styles.big}>
              <img alt='big' src={selected['images'][ind]} />
            </div>
            <div className={styles.smalls}>
              {selected['images'].map((item, i) => (
                <img onClick={() => setInd(i)} alt='small' src={item} />
              ))}
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.cont}>
              <div className={styles.comp}>
                <div className={styles.title}>First Homes Africa</div>
                <div className={styles.key}>...rent to own</div>
              </div>
              <div className={styles.top}>
                <h2>₦ {currency(selected['price'], true)}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const houseGallery = [
    {
      original: gallery1,
      thumbnail: gallery1,
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery2,
      thumbnail: gallery2
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery3,
      thumbnail: gallery3
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery4,
      thumbnail: gallery4
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery5,
      thumbnail: gallery5
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery6,
      thumbnail: gallery6
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery7,
      thumbnail: gallery7
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery8,
      thumbnail: gallery8
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery9,
      thumbnail: gallery9
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery10,
      thumbnail: gallery10
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery11,
      thumbnail: gallery11
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery12,
      thumbnail: gallery12
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery13,
      thumbnail: gallery13
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery14,
      thumbnail: gallery14
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery15,
      thumbnail: gallery15
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery16,
      thumbnail: gallery16
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery17,
      thumbnail: gallery17
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery18,
      thumbnail: gallery18
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery19,
      thumbnail: gallery19
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery20,
      thumbnail: gallery20
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery21,
      thumbnail: gallery21
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery22,
      thumbnail: gallery22
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery23,
      thumbnail: gallery23
    },
    {
      originalClass: `${styles.slideImg}`,
      thumbnailClass: `${styles.slidethumb}`,
      original: gallery24,
      thumbnail: gallery24
    }
  ];

  const Card = ({ price, type, address, state }) => (
    <div className={styles.cardbox}>
      <div className={styles.grp}>
        <div className={styles.title}> {type}</div>
        <div>
          {address} {state}
        </div>
      </div>
      <div className={styles.price}>From &#8358;{price.toLocaleString()}</div>
    </div>
  );

  return (
    <div className={styles.gallery}>
      <Nav showBackground />
      {show && <Modal />}
      <div className={styles.content}>
        <div className={styles.main}>
          <ImageGallery
            items={houseGallery}
            lazyLoad={true}
            showBullets={true}
            autoPlay={true}
            additionalClass={styles.slide}
            showThumbnails={false}
          />

          <div className={styles.locations}>
            <div className={styles.top}>Available Locations</div>
            {housesArr.map(house => (
              <Card
                price={house.cost}
                type={house.type}
                state={house.state}
                address={`${house.address} ${house.location}`}
              />
            ))}
          </div>

          {/* <div className={styles.new}>
            <div className={styles.cards}>
              {allHouses.map((house, i) => (
                <House house={house} key={i} index={i} />
              ))}
            </div>
          </div> */}
        </div>
      </div>

      <Footer />
    </div>
  );
};
export default Gallery;
