import React from "react";
import styles from "./index.module.scss";
import Nav from "../../components/Nav";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

const Terms = () => {
  return (
    <div className={styles.terms}>
      <Nav showBackground />
      <div className={styles.main}>
        <div className={styles.head}>
          <div className={styles.top}>TERMS OF USE</div>
          <div className={styles.sub}>Effective Date: 1 September 2019</div>
        </div>
        <div className={styles.body}>
          <p>
<p class="p4">Thank you for using www.Firsthomesafrica.com, or the First Homes Africa Mobile Application (the &ldquo;First Homes App&rdquo;). By using the Website or clicking the &ldquo;Sign In&rdquo; button or downloading, installing, accessing or using the First Homes Africa App, you are agreeing to be bound by the terms of use described below (the &ldquo;Terms of Use&rdquo;), so please read them very carefully. IF YOU DO NOT WISH TO BE BOUND BY THESE TERMS OF USE, DO NOT (i) ACCESS OR USE THE WEBSITE OR THE FIRSTHOMES AFRICA APP; (ii) CLICK THE &ldquo;ACCEPT&rdquo; BUTTON; OR (iii) DOWNLOAD THE FIRSTHOMES AFRICA APP. These Terms of Use are provided on behalf of First Homes Africa Limited and our affiliates (together, &ldquo;First Homes Africa&rdquo; and affiliates &ldquo;we&rdquo;, or &ldquo;us&rdquo;).</p>
<p class="p1"><strong>Privacy</strong></p>
<p class="p4">Your privacy is very important to us. Our <span class="s1">privacy policy</span> explains how we treat your personal information and protect your privacy when you use our Website or the First Homes Africa App. We will not share or sell any of your information with any third party, except as specifically described in our <span class="s1">privacy policy</span>, which is hereby incorporated by reference into these Terms of Use.</p>
<p class="p1"><strong>Information Sharing and Social Features</strong></p>
<p class="p4">You may have the opportunity to share content or information, including, but not limited to, your name, email address, or other information that you provide via the Website or the First Homes Africa App, with other users of the Website or the First Homes Africa App. By sharing information or content on the Website or the First Homes Africa App, you expressly agree to all the following:</p>
<ul class="ul1">
<li class="li5">You grant First Homes Africa a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any information or content that you post on or in connection with your use of the Website or the First Homes Africa App, subject to the privacy provisions described in our <span class="s1">privacy policy</span>.</li>
<li class="li5">You understand that deleted content or information may continue to exist on our servers or in backup copies that we maintain for our own use.</li>
<li class="li5">You will not post content or take any action on the Website or the First Homes Africa App that may constitute libel or slander, infringes or violates someone else&rsquo;s rights, that is protected by any copyright or trademark, or otherwise violates the law.</li>
<li class="li5">Content and information that you share will be available to other users of our Website or the First Homes Africa App. You understand that such third parties may access, use, store and transfer such content and information and to associate it with you.</li>
<li class="li5">You agree that you will not use the Website or the First Homes Africa App for commercial purposes.</li>
<li class="li5">We may remove any content or information that you share on the Website or the First Homes Africa App for any reason.</li>
</ul>
<p class="p4">You agree that you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or First Homes Africa App, or any transaction being conducted on our Website or the First Homes Africa App.</p>
<p class="p1" id="link-to"><strong>Lease Pre-Registrations and Applications</strong></p>
<p class="p4">You <span class="s2">shall be</span> presented with the opportunity to pre-register and/or apply for a <span class="s2">l</span>ease through the Website or the First Homes Africa App. When pre-registering or applying for a lease on the Website or the First Homes Africa App, you agree to provide current, complete, and accurate information about yourself. If any information you provide is untrue, inaccurate, not current, or incomplete, First Homes Africa<span class="s2"> reserves </span>the right to <span class="s2">decline</span> your <span class="s2">lease </span>pre-registration and/or application and to<span class="s2"> further</span> decline <span class="s2">you access to </span>the Website <span class="s2">and</span> the First Homes Africa App.</p>
<p class="p6">By registering on the First Homes Africa platform you are under no obligation to enter into a lease to own agreement. Pre-registration on the First Homes Africa is an expression of your interest to enter into a lease to own agreement with First Homes Africa at a future date; which is subject to you making regular contributions over a pre-agreed period. Your legal obligation under a lease to own agreement commences when you receive the keys the to your chosen property.</p>
<p class="p6">At the point of registration, First Homes Africa requests that you select your preferences. The request for your preference is to aggregate the demand for housing in a specific area or state. The strength of the demand in a particular area, amongst other factors will determine the location of the actual build. At the time of initiating your option to enter into a lease to own agreement, if the location of the build is not suitable,<span class="Apple-converted-space">&nbsp; </span>you can either exit the scheme or elect to remain on the scheme until your preference becomes available.</p>
<p class="p6">At the end of the pre-registration period, you initiate your option to enter into a lease to buy agreement. Once you initiate your option, your contribution is locked with First Homes Africa while due diligence is carried out and your lease to own agreement is signed. Thereafter you are handed the keys to your home.&nbsp;</p>
<p class="p7">&nbsp;</p>
<p class="p1"><strong>Your Account</strong></p>
<p class="p4">You may be required to create an account to <span class="s2">access</span> certain parts of the Website or the First Homes Africa App. When you create an account, you will be required to pick a user name and a password. You may not disclose your user name or password to any third party. If you learn of any unauthorized use of your password or account, please contact us immediately.</p>
<p class="p4">The First Homes Africa App is intended to be a supplement, not a replacement, to your other<span class="s2"> online</span> First Homes Africa Accounts (your &ldquo;Accounts&rdquo;). All agreements and disclosures that you have entered into in connection with or that apply to your Account(s) with First Homes Africa and affiliates each and every current and future affiliate of Firs<span class="s2">t </span>Homes Africa Limited, also apply to the First Homes Africa App and your use thereof.</p>
<p class="p8">Upon opening a First Homes Africa Account, you will be able to carry out the following transactions (a) effect a credit transaction by making a payment from your bank card and (b) debit transaction by sending a transfer instruction to First Homes Africa. Upon any credit or debit transaction being made; the First Homes Africa platform shall credit or debit your First Homes Africa vault once the sum is credited from or transferred to your bank. The First Homes Africa platform will verify and confirm all transactions to/from your account by e-mail and/or SMS to you. The First Homes Africa records will be taken as correct unless the contrary is proved.</p>
<p class="p8">All credit and debit transactions from your First Home Africa account will be authorised with the password which you choose when you register, or by such other method we may prescribe from time to time. Any debit transactions made using your password will be debited to your vault. You acknowledge that, unless and until First Homes Africa receives notice from you that your password is no longer secure, First Homes Africa may rely on the use of your password as conclusive evidence that a debit transaction has been authorised by you.</p>
<p class="p8">Each transaction made on the First Homes Africa platform will be issued with a unique receipt number that is included in the confirmation SMS and/or email. This receipt number is used to track &amp; identify all transactions carried out on your account.</p>
<p class="p8">Only one password can be linked to your Account at any time. You are responsible for the safekeeping and proper use of your password.&nbsp;</p>
<p class="p8">When you contact the call centre, the verification to identify that you are the rightful owner of the Account will include among other things but not limited to your ID type, ID number, personal details, date of birth, balance in your First Homes Africa vault. <strong>FIRST HOMES AFRICA SHALL NEVER REQUEST THAT YOU DISCLOSE YOUR PASSWORD. AS SUCH YOU MUST NOT DISCLOSE YOUR PASSWORD TO ANY ONE AT FIRST HOMES AFRICA INCLUDING THE CALL CENTRE REPRESENTATIVE </strong></p>
<p class="p8">You will be responsible for all applicable charges at the published tariffs for any transaction made. First Homes Africa reserves the right to vary the published tariffs at any time and with 30 days prior notice. Such variations will be notified by way of SMS, via email through our Website and/or by using any other suitable means. By continuing to use the First Homes Africa platform you shall be deemed to have agreed to the tariff variation. If you do not accept the variation then you must discontinue the use of the platform.</p>
<p class="p8">First Homes Africa hereby declares that it holds all payments received, in trust for you and for your benefit and that you shall be beneficially entitled to the credit amounts in your vault. You agree that First Homes Africa may treat the records on its platform as conclusive evidence of the amount of money standing to your credit and that First Homes Africa is not bound to make any independent investigation of your beneficial entitlement to amounts in your vault.</p>
<p class="p8">You acknowledge that First Homes shall have no obligation to invest the credit amount held other than by way of holding the same in a trust account. You further acknowledge that, to the extent that any interest accrues on the trust amounts, you shall have beneficial entitlement to such interest to the extent determined by First Homes Africa who shall be entitled to retain such interest to defray its own costs and expenses.</p>
<p class="p9">&nbsp;</p>
<p class="p1"><strong>Use of the First</strong> <strong>Homes Africa App</strong></p>
<p class="p4">You agree to use the First Homes Africa App in accordance with these Terms of Use and any applicable usage rules of any third-party mobile telephone, tablet or other device (each a &ldquo;Mobile Device&rdquo;) or service provider or the third party from whom you are downloading this First Homes Africa App (the &ldquo;App Venues&rdquo;). Supported App Venues include iTunes and Google Play; other App Venues are NOT supported. It is your responsibility to review the terms and conditions of any App Venue and to determine what usage rules of such App Venue apply to you depending on (i) your Mobile Device, (ii) the method by which you downloaded the First Homes Africa App, and (iii) the App Venue from which you downloaded the First Homes Africa App (collectively, the &ldquo;Usage Rules&rdquo;).</p>
<p class="p4">First Homes Africa may offer services to you through the First Homes Africa App (the &ldquo;Services&rdquo;). You acknowledge and understand that certain Services available to you through the First Homes Africa App, including, but not limited to, your ability to conduct financial transactions or alter your p<span class="s2">lan</span> or view your <span class="s2">vault</span> balances, require data access or wireless internet capability. You agree that you are responsible for any such data access or wireless internet charges of your Mobile Device service provider. The First Homes Africa App is designed to be accessible on multiple types of Mobile Devices and operating systems. First Homes Africa does not, however, make any representation or warrant that the First Homes Africa App will be compatible with your Mobile Device or any specific operating system version of your Mobile Device or any other hardware, software, equipment or device installed on or used in connection with your Mobile Device. You agree that your functional use of the First Homes Africa App may be affected by or dependent on your wireless connection and speed or your wireless service provider.</p>
<p class="p4">The functionality of the First Homes Africa App could become disabled during times of poor connections or speeds from your wireless or internet service provider while conducting financial transactions, including, but not limited to, when you are attempting to change your p<span class="s2">lan</span>. You agree that it is your responsibility to verify the status of any attempted transaction by reviewing your Account or contacting First Homes Africa Customer Support at +234<span class="s2">7008000500 </span>or online at www.FirstHomes Africa.com. You acknowledge and agree that First Homes Africa, and its affiliates, agents and licensors, shall have no liability to you for any losses, financial or otherwise, suffered by you arising out of or resulting from compatibility or inoperability problems or the failure of or your failure to confirm any attempted transaction.</p>
<p class="p4">You acknowledge, understand and agree that your use of the First Homes Africa App and your access to your Account through the First Homes Africa App is governed by your Account Agreements to which you have previously agreed to with, First Homes <span class="s2">Africa and</span> each and every current and future affiliate of First Homes Africa., in addition to these Terms of Use.</p>
<p class="p1"><strong>Changes and Modifications</strong></p>
<p class="p4">First Homes Africa reserves the right to temporarily or permanently modify or discontinue the Website or the First Homes Africa App, or any portion of the Website or the First Homes Africa App, for any reason, at our sole discretion, and without notice to you. First Homes Africa may also change the Terms of Use from time to time without notice to you. Please review these Terms of Use from time to time, because your continued access or use of the Website or the First Homes Africa App after any modifications have become effective shall be deemed your conclusive acceptance of the modified Terms of Use.</p>
<p class="p1"><strong>Links</strong></p>
<p class="p4">This Website or the First Homes Africa App may contain links to websites maintained by non-affiliated third parties. Such websites may have Terms of Use, Privacy Policies, or security practices that are different from those of <span class="s2">First Homes Africa</span>. First Homes Africa does not endorse or recommend, and hereby disclaims any liability for, any information or products offered at such websites.</p>
<p class="p1"><strong>No Warranty</strong></p>
<p class="p4">First Homes Africa does not warrant the accuracy, adequacy or completeness of the information provided on the Website or First Homes Africa App and expressly disclaims liability for any errors or omissions in such information. No warranty of any kind, implied, expressed or statutory including but not limited to the warranties of non-infringement of third party rights, title, merchantability, fitness for a particular purpose and freedom from computer virus, is given in conjunction with the information. Calculators and tools on the Website or First Homes Africa App provide you with ESTIMATES that may be different than actual amounts.</p>
<p class="p1"><strong>Use of Personal Data</strong></p>
<p class="p4">First Homes Africa and its subsidiaries use third party service providers to retrieve certain personal data from financial institutions which hold accounts on your behalf. These service providers, including but not limited to, retrieve financial information such as holdings, balances and transactions. First Homes Africa and its subsidiaries use this personal data for our business purposes in a manner that is consistent with our Privacy Statement.</p>
<p class="p4">First Homes Africa and its subsidiaries may use your personal data, user data and other information collected through the Website or First Homes Africa App to help us improve the content and functionality of the Website or First Homes Africa App, to better understand our customers and markets, to improve our products and services and to effect and facilitate sales.</p>
<p class="p4">First Homes Africa and its subsidiaries may use this information to contact you, electronically, through telemarketing or otherwise, in the future to tell you about, and to offer for sale, products or services we believe will be of interest to you. If we do so, each communication we send you will contain instructions permitting you to &ldquo;opt out&rdquo; of receiving future communications.</p>
<p class="p1"><strong>Consumer Communications</strong></p>
<p class="p4">By accepting these Terms of Use, you expressly consent to be contacted by First Homes Africa, our agents, representatives, affiliates, or anyone calling on our behalf for any and all purposes arising out of or relating to your account, which includes, but not limited to, your user profile or any account used in connection with a product offered by First Homes Africa. or its affiliates, at any telephone number, or any physical, email, or electronic address you provide or at which you may be reached. You agree we may contact you in any way, including SMS messages (including text messages) calls using pre<span class="s2">-</span>recorded messages or artificial voice, and calls and messages delivered using automatic telephone <span class="s2">dialling</span> systems (auto-<span class="s2">dialler</span>) or an automatic texting system. Automated messages may be played when the telephone is answered, whether by you or someone else. In the event that an agent or representative calls, he or she may also leave messages on your answering machine, voice mail, or send messages via text. You certify, warrant and represent that the telephone numbers that you have provided to us are your contact numbers. You represent that you are permitted to receive calls at each of the telephone numbers you have provided to us. You agree to promptly alert us whenever you stop using a particular telephone number. FIRSTHOMES AFRICA AND OUR AGENTS, REPRESENTATIVES, AFFILIATES AND ANYONE CALLING ON OUR BEHALF MAY USE SUCH MEANS OF COMMUNICATION DESCRIBED IN THIS SECTION EVEN IF YOU WILL INCUR COSTS TO RECEIVE SUCH PHONE MESSAGES, TEXT MESSAGES, E-MAILS OR OTHER MEANS.</p>
<p class="p4">1. In order to access, view, and retain SMS Notifications that we make available to you, you must have: (i) a SMS-capable mobile phone, (ii) an active mobile phone account with a communication service provider; and (iii) sufficient storage capacity on your mobile phone.</p>
<p class="p4">2. All SMS Notifications in electronic format from us to you will be considered &ldquo;in writing.&rdquo;</p>
<p class="p4">3. There is no service fee for SMS Notifications but you are responsible for any and all charges, including but not limited to fees associated with text messaging, imposed by your communications service provider. Please consult your mobile service carrier&rsquo;s pricing plan to determine the charges for sending and receiving text messages. These charges will appear on your phone bill. Message frequency depends on account status or settings. We may modify or terminate our text messaging services from time to time, for any reason, and without notice, including the right to terminate text messaging with or without notice, without liability to you.</p>
<p class="p1"><strong>Push Notifications (&ldquo;Alerts&rdquo;)</strong></p>
<p class="p4">You agree to be contacted by First Homes Africa, our agents, representatives, affiliates, about your account activity, balances, and other information, such as payment reminders or suspicious activity. When you consent to these Terms of Use, you are agreeing to receive Alerts through push notification(s). Receipt of Alerts may be delayed or prevented by factor(s) affecting your internet/phone provider or other relevant entities. First Homes Africa is not liable for losses or damages arising from:</p>
<ul class="ul1">
<li class="li4">Non-delivery, delayed delivery, or the erroneous delivery of any Alert.</li>
<li class="li4">Inaccurate Alert content.</li>
<li class="li4">Your use or reliance on the contents of any Alert for any purposes.</li>
</ul>
<p class="p4">The information in any Alert may be subject to certain delays. Each Alert may not be encrypted, and may include your name and information pertaining to your account(s). First Homes Africa may terminate your use of Alerts at any time without notice. You may choose to discontinue receiving Alerts by updating your First Homes Africa App Notifications preferences.</p>
<p class="p1"><strong>Call Recording</strong></p>
<p class="p4">You agree that First Homes Africa and our agents, representatives, affiliates or anyone calling on our behalf may contact you on a recorded line.</p>
<p class="p1"><strong>Limitation of Liability</strong></p>
<p class="p4">You agree that all access and use of the Website, the First Homes Africa App and their contents is at your own risk. In no event shall First Homes Africa be held liable for any damages, including without limitation direct or indirect, special, incidental, or consequential damages, losses or expenses arising in connection with the Website or the First Homes Africa App or any linked site or use thereof or inability to use by any party, or in connection with any failure of performance, error, omission, interruption, defect, delay in operation or transmission, computer virus or line or system failure, even if <span class="s2">First Homes Africa</span>, or representatives thereof, are advised of the possibility of such damages, losses or expenses.</p>
<p class="p1"><strong>First</strong> <strong>Homes Africa Community Terms of Use</strong></p>
<p class="p1"><strong>First</strong> <strong>Homes Africa Community Profile</strong></p>
<p class="p4">In conjunction with your Account(s), you may be able to create a First Homes Africa Community profile, which is subject to these specific First Homes Africa Community Terms of Use in addition to all other Terms of Use contained herein.</p>
<p class="p4">Through your First Homes Africa Community profile, you may have the option to link your profile to other social media accounts, in which case you will be prompted to link your other social media profile with your First Homes Africa Community profile. If you chose to do so, First Homes Africa will collect information from your other social media account. More information about the type of information we collect from you and how First Homes Africa uses it is available in our <span class="s1">privacy policy</span>. When you create a First Homes Africa Community profile with us, you must provide us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of these Terms of Use, which may result in immediate termination of your First Homes Africa Community profile.</p>
<p class="p4">Upon creation of a First Homes Africa Community profile, information that is obtained from your First Homes Africa account and information that you provide First Homes Africa will be on your First Homes Africa Community profile, and it will be possible for other First Homes Africa Community members to see your profile. This information is separate from information you may have provided in connection with a service made available to you by First Homes Africa. Changes made to your First Homes Africa Community profile will not be reflected in any other existing or prior Accounts<span class="s2">.</span></p>
<p class="p4">We may terminate or suspend your First Homes Africa Community profile immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms of Use. Upon termination, your right to use First Homes Africa Community will immediately cease. If you wish to terminate your First Homes Africa Community profile, you may simply delete your profile and discontinue using First Homes Africa Community.</p>
<p class="p1"><strong>First</strong> <strong>Homes Africa Community Information Sharing and Social Features</strong></p>
<p class="p4">In addition to the above Information Sharing and Social Features terms, content posted on First Homes Africa Community will also be subject to the following terms.</p>
<p class="p4">Any content you post may be viewed by any other member of First Homes Africa Community. Content that you post must comply with all applicable regulations and laws. If we believe any content that you post is offensive, inappropriate, or otherwise objectionable, we may remove it at our sole discretion. First Homes Africa reserves the right to access your profile and content if required to do so in order to cooperate with any legal process, to enforce these Terms, to respond to complaints from third parties related to content you have posted, or to protect the reputation, property, and personal safety of First Homes Africa or any other person or entity.</p>
<p class="p4">First Homes Africa takes no responsibility for the content that you choose to post on the First Homes Africa Community. You may not engage in <span class="s2">behaviour</span> that is offensive, inaccurate, incomplete, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, illegal, or violates another member&rsquo;s rights when using First Homes Africa Community. First Homes Africa reserves the right, but is not obligated, to monitor and review content you post, which may include deletions if, in First Homes Africa&rsquo;s sole judgment, the content is a breach of these Terms of Use or if the content otherwise could harm First Homes Africa&rsquo;s reputation. By posting content on First Homes Africa Community, you grant to First Homes Africa a non-exclusive, transferable, sub-licensable, fully paid-up, worldwide right and license to use your content in any way. You are also prohibited from providing false information on First Homes Africa Community. If you find that there is information on your profile that is no longer accurate, it is your responsibility to edit it or alert First Homes Africa.</p>
<p class="p4">The following is a brief list of the type of content that is prohibited by these Terms of Use, which is not meant to be all-encompassing:</p>
<ul class="ul1">
<li class="li5">Posting material that violates a third party&rsquo;s copyright.</li>
<li class="li5">Posting inappropriate photos, such as a photo containing nudity or violence.</li>
<li class="li5">Providing misleading or false information.</li>
<li class="li5">Threatening physical harm.</li>
<li class="li5">Soliciting members with &ldquo;junk mail&rdquo; or &ldquo;spam&rdquo;.</li>
<li class="li5">Promoting racist, sexist, or bigoted statements against any group or individual.</li>
<li class="li5">Soliciting personally identifiable information from members for commercial or unlawful purposes.</li>
<li class="li5">Stalking or harassing any members.</li>
<li class="li5">Promoting any illegal activities.</li>
</ul>
<p class="p1"><strong>Other Members&rsquo; Use of First</strong> <strong>Homes Africa Community</strong></p>
<p class="p4">First Homes Africa makes no representations or warranties as to the conduct of members on First Homes Africa Community. Under no circumstances will First Homes Africa be held liable for any losses or damages whatsoever, including direct, indirect, general, special, compensatory, consequential and/or incidental damages, as a result of conduct from you or any other member. You are solely responsible for your interactions with other members.</p>
<p class="p4"><strong>YOU UNDERSTAND THAT FIRST</strong> <strong>HOMES AFRICA CURRENTLY DOES NOT CONDUCT CRIMINAL BACKGROUND CHECKS OR SCREENINGS ON FIRSTHOMES AFRICA COMMUNITY MEMBERS. FIRSTHOMES AFRICA ALSO DOES NOT INQUIRE INTO THE BACKGROUNDS OF ALL FIRSTHOMES AFRICA COMMUNITY MEMBERS OR ATTEMPT TO VERIFY THE STATEMENTS OF FIRSTHOMES AFRICA COMMUNITY MEMBERS. FIRSTHOMES AFRICA MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF FIRSTHOMES AFRICA COMMUNITY MEMBERS OR THEIR COMPATIBILITY WITH ANY CURRENT OR FUTURE FIRSTHOMES AFRICA COMMUNITY MEMBERS. FIRSTHOMES AFRICA RESERVES THE RIGHT TO CONDUCT ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX OFFENDER REGISTER SEARCHES), AT ANY TIME AND USING AVAILABLE PUBLIC RECORDS.</strong></p>
<p class="p1"><strong>First</strong> <strong>Homes Africa App End-User License</strong></p>
<p class="p1"><strong>License Grant and Restrictions on Use</strong></p>
<p class="p4">The First Homes Africa App is licensed, not sold, to you by First Homes Africa. for use strictly in accordance with the terms and conditions of the Terms of Use and this license. Upon downloading the First Homes Africa App, First Homes Africa grants you a personal, revocable, non-exclusive, non-transferable limited license to install and use the First Homes Africa App on your Mobile Device to access and use the Services for your personal, non-commercial use and display strictly in accordance with this license, your Account Agreements, these Terms of Use and any applicable Usage Rules (the &ldquo;License&rdquo;). This License is granted solely for the use and purpose as intended for the First Homes Africa App.</p>
<p class="p4">You agree that you shall not (i) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the First Homes Africa App; (ii) make any modification, adaptation, improvement, enhancement, translation, copy or derivative work from the First Homes Africa App; (iii) violate any applicable laws, rules or regulations in connection with your access or use of the First Homes Africa App; (iv) remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of First Homes Africa or its affiliates, partners, suppliers or the licensors of the First Homes Africa App; or (iv) create, design, engineer or use any &ldquo;bot&rdquo; or other technology in connection with the First Homes Africa App.</p>
<p class="p1"><strong>Intellectual Property Rights of the First</strong> <strong>Homes Africa App</strong></p>
<p class="p4">You acknowledge and agree that the First Homes Africa App and all copyrights, patents, trademarks, trade secrets and other intellectual property rights associated therewith are owned by First Homes Africa or its affiliates, agents or licensors, as applicable. All rights, title and interest in and to the First Homes Africa App are reserved by First Homes Africa and its affiliates, agents or licensors, as applicable. All trademarks (including logos, service marks, trade names and icons), copyrights, patents, trade secrets, content, publicity rights and other intellectual property (collectively, the &ldquo;Intellectual Property&rdquo;) are proprietary to First Homes Africa, its affiliates, agents or licensors. You may not remove any proprietary notices, including, but not limited to, copyright and trademark notices, from the First Homes Africa App or alter, obscure or modify such notices in any way. Except as expressly stated in this license, you are not granted any intellectual property rights in or to the First Homes Africa App by implication, estoppel or other legal theory, and all rights in and to the Application not expressly granted in this License are hereby reserved and retained by First Homes Africa.</p>
<p class="p4">You acknowledge and agree that, in the event of a third-party claim that the application infringes any third party&rsquo;s intellectual property rights caused by or arising out of your use of the First Homes Africa App, you (and not First Homes Africa or any third-party Mobile Device service provider or App Venue) shall be solely responsible for the investigation, <span class="s2">defence</span>, settlement and discharge of any intellectual property infringement claim or suit, any third-party claims or any other harm or damages caused by, arising out of or resulting from your use of or access to the First Homes Africa App. You hereby release First Homes Africa and its employees, officers, directors, affiliates, agents and licensors from any and all liability caused by, arising out of or resulting from your use of the First Homes Africa App or the Services, including from product liability claims or any claim that the First Homes Africa App fails to conform to any legal or regulatory requirement. You will, however, promptly notify First Homes Africa in writing of any such claim.</p>
<p class="p4">Because First Homes Africa values the protection of its intellectual property, it seeks to respect other individual&rsquo;s intellectual property rights. If you believe that material on our First Homes Africa App violates your copyright, please notify us at <span class="s4">business</span>@<span class="s5">firsthomesafrica.com</span> . First Homes Africa will respond to all copyright infringement notices that it receives and respond appropriately by removing infringing material if necessary. If a member repeatedly infringes upon copyrights, his or her account may be subject to termination at First Homes Africa&rsquo;s discretion.</p>
<p class="p4">You may not rent, lease, lend, sublicense or transfer the Application, this License or any of the rights granted hereunder. Any attempted transfer in contravention of this provision shall be null and void and of no force or effect.</p>
<p class="p1"><strong>Indemnification</strong></p>
<p class="p4">You shall indemnify, defend and hold harmless First Homes Africa and its affiliates, partners, suppliers and licensors, and each of their respective officers, directors, agents and employees (the &ldquo;Indemnified Parties&rdquo;) from and against any claim, proceeding, loss, damage, fine, penalty, interest and expense (including, without limitation, fees for attorneys and other professional advisors) arising out of or in connection with the following: (i) your access to or use of the First Homes Africa App; (ii) your breach of this License; (iii) your violation of law in connection with this License; (iv) your negligence or <span class="s2">wilful</span> misconduct in connection with this License; or (v) your violation of the rights of a third party, including the infringement by you of any intellectual property or misappropriation of any proprietary right or trade secret of any person or entity. These obligations will survive any termination of the License.</p>
<p class="p1"><strong>Export Control</strong></p>
<p class="p4">You may not use or otherwise export or re-export the Application except as authorized by <span class="s2">Nigeria law</span> and the laws of the jurisdiction(s) in which the Application was obtained. You also agree that you will not use the Application for any purposes prohibited by <span class="s2">Nigerian law</span>.</p>
<p class="p1"><strong>Term</strong></p>
<p class="p4">This License shall be effective until terminated.</p>
<p class="p1"><strong>Termination</strong></p>
<p class="p4">First Homes Africa may, in its sole and absolute discretion, at any time and for any or no reason and with or without prior notice, suspend or terminate this License and the rights afforded to you hereunder. Furthermore, if you fail to comply with any terms and conditions of this License, then this License and any rights afforded to you hereunder shall terminate automatically, without any notice or other action by First Homes Africa. Upon the termination of this License, you shall cease all use of the Application and uninstall the Application.</p>
<p class="p1"><strong>Applicable Law</strong></p>
<p class="p4">You agree to abide by all applicable local, state, national, and international laws and regulations in your use of the Website or the First Homes Africa App. Our Website and the First Homes Africa App is available only to individuals who are permitted to use it under applicable law. Additionally, the Website and First Homes Africa App are intended only for personal, non-commercial use and display by N<span class="s2">igerian </span>residents aged 18 years and over. If you do not qualify, please do not use our Website or the First Homes Africa App. You agree to comply with all applicable laws, rules and regulations regarding the export of technical data from the <span class="s2">NIGERIA.</span> You agree to be solely responsible for your actions and the contents of your transmissions through the Website or the First Homes Africa App. You agree not to impersonate any person or entity or falsely state or otherwise misrepresent your identity or affiliation with a person or entity. You represent and warrant that you possess the legal right and ability to enter into these Terms of Use and the License, and to use the Website or the First Homes Africa App in accordance with these Terms of Use. Unauthorized use of this Website or the First Homes Africa App, including but not limited to unauthorized entry into <span class="s2">First Homes Africa</span>&rsquo;s systems, misuse of passwords, or misuse of any information posted on a site, is strictly prohibited.</p>
<p class="p4">The failure of First Homes Africa to exercise or enforce any right or provision of the Terms of Use shall not constitute a waiver of such right or provision. If any provision of the Terms of Use or the License is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should <span class="s2">endeavour</span> to give effect to the parties&rsquo; intentions as reflected in the provision, and the other provisions of the Terms of Use or the License remain in full force and effect. You agree that regardless of any statute, regulation, or law to the contrary, any claim or cause of action you may have arising out of or related to use of the Website, the First Homes Africa App, or these terms of Use must be filed within fourteen days (14) after such claim or cause of action arose or be forever barred.</p>
<p class="p4">Please direct all questions or comments about these Terms of Use to <span class="s5">support@firsthomesafrica.com</span>&nbsp;</p>
<p class="p4">COPYRIGHT &copy; First Homes Africa. ALL RIGHTS RESERVED.</p>
<p class="p4">First Homes Africa owns the copyright in all of the information and material contained in this Website and the First Homes Africa App. The contents of the website, including its &ldquo;look and feel&rdquo; (e.g., text, graphics, images, logos, and button icons), editorial content, notices, software (including html-based computer programs), and other material are protected under both <span class="s2">Nigeria and</span> foreign copyright, patent, trademark, and other laws. Your right to use the Website and the First Homes Africa App is personal to you. You agree not to reproduce, duplicate, copy, sell, resell, use or exploit for any commercial purposes, the Website or the First Homes Africa App, or use of or access to the Website or the First Homes Africa App, or any information or technology obtained from the Website or the First Homes Africa App, including, but not limited to, First Homes Africa&rsquo;s trademarks. If you believe that any material on this Website or the First Homes Africa App, including any material posted by members, violates your copyright, please notify us at <span class="s5">business@firsthomesafrica.com</span>. First Homes Africa will respond to all copyright infringement notices that it receives and respond appropriately by removing infringing material if necessary.</p>
<p class="p1"><strong>First</strong> <strong>Homes Africa&rsquo;s Referral Program</strong></p>
<p class="p4">By agreeing to these Terms of Use, you are agreeing to be bound by the terms of First Homes Africa&rsquo;s Referral Program (&ldquo;Program&rdquo;) contained herein. This Program is open to all persons who have created a First Homes Africa account, who are of the legal age (&ldquo;Eligible Referrer(s)&rdquo;). Notwithstanding the above, First Homes Africa Employees are not eligible to participate in this Program.</p>
<p class="p4">To receive credit for a referral under the Program, Eligible Referrers must first create a Program account, receive their unique referral link, and send the link to an Eligible Recipient (defined below), whom they intend to refer to First Homes Africa. The Eligible Recipient then must use their Eligible Referrer&rsquo;s unique referral link to register for a First Homes Africa account and then proceed <span class="s2">to make a deposit on the website and/or First Homes Africa App</span>.</p>
<p class="p4">By referring Eligible Recipients via <span class="s2">email,</span> you agree to allow us to send an email on your behalf. Your Eligible Recipient&rsquo;s email addresses will only be used for this offer and will not be used for any other marketing solicitations or sold to third parties. If the Eligible Recipient already has a First Homes Africa account, or if the email address you provided is incorrect or no longer valid, the Eligible Recipient may not receive the email. Due to the confidential nature, we cannot disclose any information about qualifying accounts opened by the Eligible Recipients you invite. Participation in this Program serves as a one-time waiver of privacy rights by both parties, whereas each may be aware of the presence of an account relationship.</p>
<p class="p4">Only one <span class="s2">matching</span> bonus will be awarded for each referred Eligible Recipient<span class="s2">. </span>Referrers will not receive credit for referring themselves<span class="s2">.</span> Eligible Referrers are limited to referring their own friends, colleagues, family members, and direct acquaintances (&ldquo;Eligible Recipient(s)&rdquo;) whom they believe may be eligible. Any Eligible Recipient must be able to personally identify their Eligible Referrer. First Homes Africa reserves the right to disqualify anyone from this Program at any time. Anyone who violates these official rules is ineligible for payment of any bonus.</p>
<p class="p4">If you participate in the Program you agree not to solicit applications for any type of First Homes Africa Lease . You agree not to compensate, or accept any compensation from, applicants whom you refer to First Homes Africa through the Program. You further agree that your activities will be limited to informing Eligible Recipients about First Homes Africa&rsquo;s Home ownership <span class="s2">scheme so</span> that they may independently decide whether or not to apply for the opportunity.</p>
<p class="p4">You must comply with all applicable laws and regulations, including anti-spam laws. Any distribution of a Program link, including by email or social media, must be done in a personal manner and must be in a form approved by First Homes Africa in advance. Bulk email distribution and any use of automated devices or third-party services is prohibited. Eligible Referrers may not purchase internet search terms or publicly advertise First Homes Africa&rsquo;s <span class="s2">product </span>or name in any fashion. Further, any distribution of a Program link that could constitute unsolicited commercial email or &ldquo;spam,&rdquo; any content which impersonates or implies an affiliation with or endorsement by First Homes Africa or any First Homes Africa subsidiary, or any actions which otherwise violate any conditions imposed by First Homes Africa, are prohibited and will be grounds for immediate termination.</p>
<p class="p10">&nbsp;</p>
<p class="p4"><strong>First</strong> <strong>Homes Africa reserves the right to temporarily or permanently modify or discontinue the Program at any time, for any reason, at our sole discretion, and without notice to you.</strong></p>
<p class="p10">&nbsp;</p>
<p class="p10">&nbsp;</p></p>
        </div>
      </div>
      <ScrollUpButton
        ToggledStyle={{ bottom: 100 }}
        style={{ borderColor: "#174a41" }}
      />
    </div>
  );
};

export default Terms;
