import React, { useState, useEffect } from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import Select from "react-select";
import { useMappedState, useDispatch } from "redux-react-hook";
import spinner from "../../assets/spinnerblue.svg";
import { Link } from "react-router-dom";
import { sendOtp, reSendOtp, setPassword } from "../../store/actions/auth";
import {
  getLocations,
  calculateHousing,
  getStates,
  getHouseTypes
} from "../../store/actions/onboard";
import eye from "../../assets/eye.svg";
import eyecl from "../../assets/eyecl.svg";
import currency from "../../utils/currency";
import CurrencyInput from "../../components/CurrencyInput";
import properNum from "../../utils/properNum";
import includesAll from "../../utils/includesAll";
import { setNotify } from "../../store/actions/notify";
const OnBoarding = ({ history }) => {
  const [current, setCurrent] = useState(0);
  const [passwords, setPasswords] = useState({});
  const [otp, setOtp] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const dispatch = useDispatch();
  const [types, setTypes] = useState({
    hasDownPayment: "yes"
  });
  const [calculate, setCalculate] = useState({
    hasDownPayment: "yes"
  });
  const [focus, setFocus] = useState(null);
  const [modal, setModal] = useState(false);
  const [show, setShow] = useState(false);
  // const [hasDownPay, setHasDownPay] = useState(true);
  const user = useMappedState(({ auth }) => auth.currentUser);
  const onboard = useMappedState(state => state.onboard);
  let icon;
  useEffect(() => {
    setInterval(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      icon = document.getElementById("drift-widget-container");
      if (icon) {
        if (icon.style.display !== "none") {
          icon.style.display = "none";
        }
      }
    }, 1000);

    dispatch(getStates());
    dispatch(getHouseTypes());

    if (!user.isVerified) {
      return setCurrent(0);
    }
    if (!user.hasPassword) {
      return setCurrent(1);
    }

    if (!user.hasMadeFirstPayment) {
      return setCurrent(2);
    }

    if (user.hasMadeFirstPayment) {
      return history.push("/dashboard");
    }

    return () => {
      icon.style.display = "block";
    };
  }, [user, dispatch, history]);

  const nextPage = e => {
    e && e.preventDefault();
    if (current < 5) {
      setCurrent(current + 1);
    }
  };

  const prevPage = e => {
    e && e.preventDefault();
    if (current >= 1) {
      setCurrent(current - 1);
    }
  };

  const passwordSet = e => {
    e && e.preventDefault();
    setFocus(e.target.name);
    setPasswords({
      ...passwords,
      [e.target.name]: e.target.value
    });
  };

  const gatherCalculate = e => {
    e && e.preventDefault();
    setFocus(e.target.name);
    if (e.target.name === "housePrice") {
      if (
        e.target.value.length &&
        isNaN(e.target.value[e.target.value.length - 1])
      )
        return;
    }
    return setCalculate({
      ...calculate,
      [e.target.name]:
        e.target.type === "number" ? Number(e.target.value) : e.target.value
    });
  };
  const handleTypes = (name, data) => {
    if (name === "state") {
      setTypes({
        ...types,
        location: null
      });
      dispatch(getLocations(data.value));
    }
    setCalculate({
      ...calculate,
      [name]: data.value
    });
    setTypes({
      ...types,
      [name]: data
    });
  };

  const calculateCost = e => {
    e && e.preventDefault();

    if (
      !includesAll(Object.keys(calculate), [
        "housePrice",
        "houseType",
        "state",
        "location",
        "hasDownPayment",
        ...(calculate && calculate.hasDownPayment === "yes"
          ? ["downPaymentPercent"]
          : [])
      ])
    ) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }

    // if (properNum(calculate.housePrice) < 5000000) {
    //   return dispatch(
    //     setNotify({
    //       type: "error",
    //       title: "Apartment price too small",
    //       body: "Apartment price must be a minimum of 5,000,000 naira"
    //     })
    //   );
    // }

    dispatch(
      calculateHousing(
        { ...calculate, housePrice: properNum(calculate.housePrice) },
        nextPage
      )
    );
  };

  const handleTypesCal = (name, data) => {
    setCalculate({
      ...calculate,
      [name]: data.value
    });
    setTypes({
      ...types,
      [name]: data
    });
    return dispatch(
      calculateHousing({
        ...calculate,
        [name]: data.value,
        housePrice: properNum(calculate.housePrice)
      })
    );
  };

  const HouseTypes = onboard.houseTypes.map(house => ({
    label: house.description,
    value: house.houseType
  }));

  const states = onboard.states.map(state => ({
    label: state,
    value: state
  }));

  const locations = onboard.locations.map(location => ({
    label: location,
    value: location
  }));
  const percents = [...Array(5).keys()].map(num => ({
    label: `${(num + 2) * 5} %`,
    value: (num + 2) * 5
  }));

  const years = [...Array(14).keys()].map(num => ({
    // label: `${num + 2} year${num + 1 === 1 ? "" : "s"}`,
    label: `${num + 2} years`,
    value: num + 2
  }));

  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      backgroundColor: "white",
      minHeight: "60px",
      border: "1px solid #03684E !important",
      boxShadow: "#03684E !important"
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: "20px",
      lineHeight: "25px",
      padding: "0 10px",
      color: "rgba(62, 76, 89, .4)"
    }),
    singleValue: (styles, { data }) => ({
      ...styles,
      fontSize: "20px",
      lineHeight: "25px",
      color: "#03684E",
      padding: "10px"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "20px",
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? "#03684E"
          : isFocused
          ? "#e6f0ed"
          : null,
        color: isDisabled ? "#ccc" : isSelected ? "white" : "#03684E",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor:
            !isDisabled && (isSelected ? "transparent" : "#e6f0ed")
        }
      };
    }
  };

  const RenderStage = ({ stage }) => {
    const stages = {
      0: (
        <div>
          <div className={styles.title}>Authenticate Account.</div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div className={styles.text}>
                Enter your referral code.
                <div className={styles.optional}>* This is optional</div>
              </div>
              <div>
                <input
                  type="text"
                  name="refcode"
                  placeholder="Enter referral code"
                  value={referralCode}
                  onChange={e => {
                    e.preventDefault();
                    setFocus(e.target.name);
                    setReferralCode(e.target.value);
                  }}
                  autoFocus={focus === "refcode"}
                />
              </div>
            </div>

            <div className={styles.two}>
              <div className={styles.text}>
                Enter the activation code sent to your email address to
                authenticate your account.
                <div className={styles.optional}>
                  <Link to="#" onClick={() => dispatch(reSendOtp())}>
                    Resend OTP
                  </Link>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="otp"
                  placeholder="Enter OTP code"
                  value={otp}
                  onChange={e => {
                    e.preventDefault();
                    setFocus(e.target.name);
                    setOtp(e.target.value);
                  }}
                  autoFocus={focus === "otp"}
                />
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <div />
            <button
              className={styles.next}
              onClick={() => dispatch(sendOtp({ otp, referralCode }, nextPage))}
              disabled={user && user.loading}
            >
              {user.loading ? <img src={spinner} alt="" /> : <span>NEXT</span>}
            </button>
          </div>
        </div>
      ),
      1: (
        <div>
          <div className={styles.title}>Create Password</div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div className={styles.text}>Enter your Password</div>
              <div className={styles.passInput}>
                <input
                  name="password"
                  type={show ? "text" : "password"}
                  placeholder="Enter password"
                  value={passwords.password}
                  autoFocus={focus === "password"}
                  onChange={passwordSet}
                />
                <img
                  alt="show password"
                  src={show ? eye : eyecl}
                  className={styles.inbtn}
                  onClick={() => setShow(!show)}
                />
              </div>
            </div>

            <div className={styles.two}>
              <div className={styles.text}>Confirm password entered above</div>
              <div>
                <input
                  name="confirmPassword"
                  type={show ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoFocus={focus === "confirmPassword"}
                  value={passwords.confirmPassword}
                  onChange={passwordSet}
                />
              </div>
            </div>
          </div>

          <div className={styles.buttons}>
            <div />
            <button
              className={styles.next}
              onClick={() => dispatch(setPassword(passwords, nextPage))}
              disabled={user.loading}
            >
              {user.loading ? <img src={spinner} alt="" /> : <span>NEXT</span>}
            </button>
          </div>
        </div>
      ),
      2: (
        <div>
          <div className={styles.title}>Apartment & Location</div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div className={styles.smallText}>
                Select a preferred apartment type and a location, you can change
                this later.
              </div>
              <div className={styles.select}>
                <Select
                  name="state"
                  value={types.state}
                  onChange={data => handleTypes("state", data)}
                  placeholder="Select state"
                  options={states}
                  required={true}
                  styles={colourStyles}
                  disabled={onboard.loading}
                  autoFocus={focus === "state"}
                />
              </div>
            </div>

            <div className={styles.two}>
              <div />
              <div className={styles.select}>
                {!onboard.loading && locations.length > 0 && (
                  <Select
                    name="location"
                    defaultValue={null}
                    value={types.location}
                    onChange={data => handleTypes("location", data)}
                    placeholder="Select neighbourhood"
                    options={locations}
                    required={true}
                    styles={colourStyles}
                    autoFocus={focus === "location"}
                  />
                )}
                {onboard.loading && (
                  <img
                    src={spinner}
                    style={{
                      height: "50px"
                    }}
                    alt=""
                  />
                )}
              </div>
            </div>
            <div className={styles.two}>
              <div />
              <div className={styles.select}>
                {!onboard.loading && locations.length > 0 && (
                  <Select
                    name="houseTypes"
                    value={types.houseType}
                    onChange={data => handleTypes("houseType", data)}
                    placeholder="Select apartment type"
                    options={HouseTypes}
                    required={true}
                    styles={colourStyles}
                    autoFocus={focus === "houseTypes"}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <div />
            {types.location && (
              <button className={styles.next} onClick={nextPage}>
                NEXT
              </button>
            )}
          </div>
        </div>
      ),
      3: (
        <div>
          <div className={styles.title}>House Budget</div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div className={styles.text}>
                What is your estimated budget for your{" "}
                {types["houseType"] && types["houseType"].value}?
                {/* <div className={styles.optional}>
                  * Amount shouldn’t be more than a third of your monthly income
                </div> */}
              </div>
              <div>
                <CurrencyInput
                  name="housePrice"
                  holder="Amount"
                  num={calculate.housePrice}
                  onchange={gatherCalculate}
                  mid={true}
                  focus={focus === "housePrice"}
                />
                {/* <input
                  type="number"
                  name="housePrice"
                  placeholder="Amount"
                  value={calculate.housePrice}
                  onChange={gatherCalculate}
                  autoFocus={focus === "housePrice"}
                /> */}
              </div>
            </div>

            <div className={styles.two}>
              <div className={styles.text}>Do you have a down payment ?</div>

              <div>
                <div className={styles.choose}>
                  <button
                    onClick={() =>
                      handleTypes("hasDownPayment", { value: "yes" })
                    }
                    className={
                      calculate.hasDownPayment === "yes" ? styles.active : ""
                    }
                  >
                    Yes
                  </button>
                  <button
                    onClick={() =>
                      handleTypes("hasDownPayment", { value: "no" })
                    }
                    className={
                      calculate.hasDownPayment === "yes" ? "" : styles.active
                    }
                  >
                    No
                  </button>
                </div>
                {calculate.hasDownPayment === "yes" && (
                  <div className={styles.select}>
                    <Select
                      name="percent"
                      value={types.downPaymentPercent}
                      onChange={data => handleTypes("downPaymentPercent", data)}
                      placeholder="Percentage down payment"
                      options={percents}
                      required={true}
                      styles={colourStyles}
                    />
                  </div>
                )}
                <div className={styles.btnfull}>
                  {onboard.calculating ? (
                    <img
                      src={spinner}
                      style={{
                        height: "50px"
                      }}
                      alt=""
                    />
                  ) : (
                    <button className={styles.btn} onClick={calculateCost}>
                      CALCULATE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            {!onboard.calculating && (
              <button className={styles.prev} onClick={prevPage}>
                BACK
              </button>
            )}
            <div />
          </div>
        </div>
      ),
      5: (
        <div>
          <div className={styles.title}>
            Set a monthly target and calculate repayments
          </div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div className={styles.text}>
                Select the number of years you’d like to repay the loan amount
                for, this would be used to set your monthly contribution amount.
                <div className={styles.optional}>
                  * Maximmum number of years for repayment is 15 years, interest
                  rate is set at 15%
                </div>
              </div>

              <div>
                <div className={styles.select}>
                  <Select
                    name="years"
                    value={types.loanTerm}
                    onChange={data => handleTypesCal("loanTerm", data)}
                    placeholder="Select years"
                    options={years}
                    required={true}
                    styles={colourStyles}
                  />
                </div>
                <div className={styles.sum}>
                  <div className={styles.sumText}>
                    Based on a repayment tenor of{" "}
                    {types.loanTerm && types.loanTerm.value} Year
                    {types.loanTerm && types.loanTerm.value <= 1 ? "" : "s"},
                    your monthly repayment and subsequent contribution amount
                    is:
                  </div>
                  <div className={styles.sumCash}>
                    <span>
                      &#8358;{" "}
                      {onboard.calculation &&
                        currency(onboard.calculation.estimatedMonthlyPament)}
                    </span>
                  </div>
                </div>
                <div className={styles.btnfull}>
                  {onboard.calculating ? (
                    <img
                      src={spinner}
                      style={{
                        height: "50px"
                      }}
                      alt=""
                    />
                  ) : (
                    <button
                      className={styles.btn}
                      onClick={() => setModal(true)}
                    >
                      CONFIRM
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            {!onboard.calculating && (
              <button className={styles.prev} onClick={prevPage}>
                BACK
              </button>
            )}
            <div />
          </div>
        </div>
      ),
      4: (
        <div>
          <div className={styles.box}>
            <div className={styles.two}>
              <div>
                <div className={styles.title}>Review Plan</div>
                <br />
                <div className={styles.text}>
                  Review the details of your target plan before accepting, you
                  can recalculate your plan at anytime.
                </div>
              </div>
              <div className={styles.summary}>
                <div className={styles.divide}>
                  <div className={styles.text}>
                    ESTIMATED CONTRIBUTION PERIOD
                  </div>
                  <div className={styles.date}>
                    {onboard.calculation && onboard.calculation.savingsDuration}
                    {" Months"}
                  </div>
                </div>
                <div className={styles.divide}>
                  <div className={styles.text}>MONTHLY CONTRIBUTION</div>
                  <div className={styles.cash}>
                    &#8358;{" "}
                    {onboard.calculation &&
                      currency(onboard.calculation.estimatedMonthlyPament)}
                  </div>
                </div>

                <div className={styles.divide}>
                  <div className={styles.text}>TOTAL CONTRIBUTION</div>
                  <div className={styles.date}>
                    &#8358;{" "}
                    {onboard.calculation &&
                      currency(onboard.calculation.totalEquityContribution)}
                  </div>
                </div>
                <div className={styles.divide}>
                  <div className={styles.text}>TOTAL LOAN AMOUNT</div>
                  <div className={styles.cash}>
                    &#8358;{" "}
                    {onboard.calculation &&
                      currency(onboard.calculation.loanAmount)}
                  </div>
                </div>
                <div className={styles.divide}>
                  <div className={styles.text}>LOAN TERM</div>
                  <div className={styles.date}>
                    {onboard.calculation && onboard.calculation.loanTerm} years
                  </div>
                </div>
                <div className={styles.divide}>
                  <div className={styles.text}>TOTAL BUDGET</div>
                  <div className={styles.cash}>
                    &#8358;{" "}
                    {onboard.calculation &&
                      currency(onboard.calculation.totalBudget)}
                  </div>
                </div>
                {onboard.calculation &&
                  onboard.calculation.downPaymentPercentage !== 0 && (
                    <div className={styles.divide}>
                      <div className={styles.text}>PERCENTAGE DOWN PAYMENT</div>
                      <div className={styles.date}>
                        {onboard.calculation.downPaymentPercentage}% <br />(
                        &#8358;{" "}
                        {currency(onboard.calculation.downPaymentAmount)})
                      </div>
                    </div>
                  )}
                <div className={styles.divide}>
                  <div className={styles.text}>ESTIMATED MATURITY DATE</div>
                  <div
                    className={`${
                      onboard.calculation &&
                      onboard.calculation.downPaymentPercentage !== 0
                        ? styles.cash
                        : styles.date
                    }`}
                  >
                    {onboard.calculation && onboard.calculation.maturityDate}
                  </div>
                </div>

                <div className={styles.btns}>
                  <button className={styles.btn} onClick={nextPage}>
                    NEXT
                  </button>
                  <button className={styles.link} onClick={() => setCurrent(2)}>
                    RECALCULATE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    };
    return stages[stage];
  };

  return (
    <div className={styles.board}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div className={styles.frame}>
          <div className={styles.welcome}>
            <div className={styles.name}>Hello {user && user.firstname},</div>
            <div className={styles.desc}>
              You are a few steps away to start the journey to owning YOUR first
              home
            </div>
          </div>

          <div className={styles.transition}>
            <RenderStage stage={current} />
          </div>
        </div>
      </div>
      {modal && (
        <div className={styles.overall}>
          <div className={styles.card}>
            <div className={styles.text}>
              I have read and agree to First Homes Terms&Conditions and Privacy
              Policy.
            </div>
            <div className={styles.links}>
              <Link target="_blank" rel="noopener noreferrer" to="/terms">
                Terms & Conditions
              </Link>
              <Link target="_blank" rel="noopener noreferrer" to="/privacy">
                Privacy Policy
              </Link>
            </div>
            <button
              className={styles.btn}
              onClick={() => history.push("/dashboard")}
            >
              ACCEPT
            </button>
            <button className={styles.linkbtn} onClick={() => setModal(false)}>
              CANCEL
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OnBoarding;
