import React, { useState } from "react";

import Footer from "../../components/Footer";
import banner from "../../assets/bannercon.png";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import { useDispatch } from "redux-react-hook";
import { sendMail } from "../../store/actions/auth";

const Contact = props => {
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  const onchange = e => {
    e.preventDefault();
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  return (
    <div className={styles.contact}>
      <Nav showBackground />
      <div className={styles.main}>
        <div
          className={styles.back}
          style={{
            background: `url(${banner})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div> We want to hear from you</div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.form}>
            <div className={styles.text}>Get in touch</div>

            <div className={styles.groups}>
              <form
                onSubmit={e => {
                  console.log("test1");

                  e.preventDefault();
                  dispatch(sendMail(details, "contact", () => setDetails({})));
                }}
              >
                <div className={styles.split}>
                  <div className={styles.group}>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First name"
                      onChange={onchange}
                      value={details.firstname || ""}
                    />
                  </div>

                  <div className={styles.group}>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last name"
                      onChange={onchange}
                      value={details.lastname || ""}
                    />
                  </div>
                </div>

                <div className={styles.split}>
                  <div className={styles.group}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={onchange}
                      value={details.email || ""}
                    />
                  </div>

                  <div className={styles.group}>
                    <input
                      type="phone"
                      name="phoneNumber"
                      placeholder="Phone number"
                      onChange={onchange}
                      value={details.phoneNumber || ""}
                    />
                  </div>
                </div>

                <div className={styles.group}>
                  <textarea
                    name="message"
                    cols=""
                    rows="10"
                    placeholder="Message"
                    onChange={onchange}
                    value={details.message || ""}
                  />
                </div>
                <div className={styles.btn}>
                  <button type="submit">SUBMIT</button>
                </div>
              </form>
              <div className={styles.address}>
                <div className={styles.head}>Our Office</div>
                <div className={styles.sub}>Address:</div>
                <div className={styles.info}>
                  7th Floor, <br /> Mulliner Towers, <br /> 39 Alfred Rewane
                  Road, <br />
                  Ikoyi, Lagos, <br /> Nigeria.
                </div>
                <div className={styles.sub}>Telephone:</div>
                <div className={styles.info}>0700 800 0500</div>
                <div className={styles.sub}>Email:</div>
                <div className={styles.info}>support@firsthomesafrica.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact;
