import React from "react";
import styles from "./index.module.scss";
import Nav from "../../components/Nav";
import { CircleArrow as ScrollUpButton } from "react-scroll-up-button";

const Privacy = () => {
  return (
    <div className={styles.privacy}>
      <Nav showBackground />
      <div className={styles.main}>
        <div className={styles.head}>
          <div className={styles.top}>Privacy Policy</div>
          <div className={styles.sub}>Effective Date: 1 September 2019</div>
        </div>
        <div className={styles.body}>
          <p>

<p class="p4">First Homes Africa is a Proptech company with a radical approach to home ownership. Our business concept is designed to fit with the lifestyle of the young African Adult in ways that guarantees home ownership, wealth creation and access to credit.</p>
<p class="p4">This Privacy Policy is meant to help you understand how we and our affiliates collect, secure, use, and disclose your personal information in connection with our services. This Privacy Policy applies to personal information we collect from you and from third parties, both online and offline. If you are a current First Homes Africa customer, you can also use our Privacy Notice as a guide to the ways we use your personal information, as well to understand your choices to limit our use and sharing of your information.</p>
<p class="p4">By using First Homes Africa websites, or otherwise providing us with personal information, you agree to the terms and conditions of this privacy policy and any updates we make. Our Terms of Use take precedent over any provision conflicting with our Privacy Policy.</p>
<p class="p4">This Privacy Policy is written to apply to First Homes Limited and its associated <span class="s2">C</span>ompa<span class="s2">ni</span>es.</p>
<p class="p4"><strong>Changes to Our Privacy Policy</strong></p>
<p class="p4">We update our Privacy Policy regularly&mdash;this version is effective as of the date indicated above. We will notify you of material changes to the Privacy Policy by email as well as a notice on our website.</p>
<p class="p1"><span class="s1"><strong>1. How and Why We Collect Information About You</strong></span></p>
<p class="p6"><strong>1.1. Information you provide us</strong></p>
<p class="p7"><strong>1.1.1. Personal Information</strong></p>

<p class="p9">We collect personal information from you to help you use our services. When we say, &ldquo;personal information,&rdquo; we mean information that specifically identifies you.</p>
<p class="p4">We collect this information in a number of ways. You are asked to provide personal information to us while registering <span class="s2">on</span> our Website, discussing your account with us, providing feedback to us, or transferring funds when using our services. Examples of personal information include:</p>
<ul class="ul1">
<li class="li10">Your Name</li>
<li class="li10">Your Address</li>
<li class="li10">Telephone Number</li>
<li class="li10">Email Address</li>
<li class="li10">Employment Information</li>
<li class="li11">Salary Information</li>
<li class="li11">Bank Verification Number (BVN)</li>
</ul>
<p class="p12">We may supplement the personal information you submit to us with information from third-party sources. For example, in an effort to protect the integrity of your account and if permitted under applicable law, we may supplement your registration Information with address and telephone standardization data to verify the address and the telephone number you have entered is valid and to protect you against identity theft.</p>
<p class="p4">Finally, we never intend to collect personal information from children under 18 years of age. If we learn or are notified that we have collected this information from a child under the age of 18, we will promptly remove the data from our system.</p>
<p class="p6"><strong>1.2. Information we collect about you</strong></p>
<p class="p7"><strong>1.2.1 Aggregate Information</strong></p>

<p class="p4">Aggregate information, which is data we collect about your use of our or third-party websites or services, from which personal information has been removed. Aggregate data is used to help us understand consumer trends, needs, interests, and preferences so we can improve our products and services.</p>
<p class="p4">First Homes Africa also collects demographic information, which is anonymous, and may include:</p>
<ul class="ul1">
<li class="li10">Age</li>
<li class="li10">Date of Birth</li>
<li class="li10">Income</li>
<li class="li13">Geographic Area</li>
</ul>
<p class="p12">In the case of employee-sponsored plans, where we partner with employers to offer our products to their employees, we will collect information from both the employer and employees. This includes, but is not limited to, employer name, plan participant names, length of employment, annual income, addresses, dates of birth, email addresses and other relevant information needed to verify the identity and employment of plan participants.</p>
<p class="p14">&nbsp;</p>
<p class="p7"><strong>1.2.2. Consumer Reports</strong></p>

<p class="p4">First Homes Africa both receives and transmits data to consumer reporting agencies. We partner with these agencies to assess your creditworthiness and to prevent fraud and identity theft. This is done as part of our regular underwriting, fraud prevention and credit servicing processes.</p>
<p class="p4">Information we collect in these reports includes, but is not limited to:</p>
<ul class="ul1">
<li class="li10">Information about open and closed credit accounts, including the date opened, the date closed (if applicable), the latest reported monthly balance and monthly payment</li>
<li class="li10">Information about credit inquiries</li>
<li class="li10">Information about late payments and collection actions occurring on open and closed credit accounts</li>
<li class="li10">Information regarding public records of bankruptcy, judgements, tax liens, and other payment statuses</li>
<li class="li10">The credit score produced by the credit bureau providing the report</li>
<li class="li13">Information about your transaction history, assets, and account balances</li>
</ul>
<p class="p5">&nbsp;</p>
<p class="p7"><strong>1.2.3. Correcting information maintained by the credit bureaus</strong></p>

<p class="p15">Information related to your creditworthiness is maintained by the credit bureaus. If you find that there is an error or you want to dispute the information found in your credit report, please contact the credit reporting bureaus.</p>
<p class="p16">&nbsp;</p>
<p class="p17"><strong>1.2.4. Website Use and Logging Data</strong></p>

<p class="p4">First Homes Africa also collects information about your computer or mobile device to improve and evaluate your use of our website and our services. We may gather data such as your browser type, operating system, loading or exiting of web pages, and the Internet Protocol (IP) address of your computer or device.</p>
<p class="p4">Please note that any information, photos, contact information, email addresses, videos, and other content you post to your profile on First Homes Africa&rsquo;s Community site is available to everyone using that service. The information you share is available to everyone accessing that service, and may be read, collected or used by others for any purpose, including sending you unsolicited messages.</p>
<p class="p7"><strong>1.2.5. Information We Get from Others</strong></p>

<p class="p4">Like other advertisers, we contract with third parties to place advertisements for our products and services on websites that are not our own. We place these ads where we think they are likely to be most relevant. These advertisements on third-party websites allow us to track responses to our ads. We and our affiliates use this information to track the performance of our advertising and marketing campaigns by using tools like cookies, web beacons and similar technologies. We use these tools to collect and store information about your visits, page visits and duration, and the specific ad or link that the user clicked on to visit the site.</p>
<p class="p7"><strong>1.2.6 Cookies</strong></p>

<p class="p4">First Homes Africa uses &ldquo;cookies&rdquo; and similar tools to track your use of the Website when you use our services. We collect information such as the types of service used, and number of users we receive daily. Our web servers automatically log information about your computer, but we don&rsquo;t use this information to identify you personally.</p>
<p class="p4">We and our vendors use cookies and to improve our service, our site and to provide more convenient and relevant experiences to you. Cookies are designed to transfer a small amount of data to your browser by servers within a domain. That data is only read by designated servers within that domain. It functions as your computer&rsquo;s identification card and enables First Homes Africa to improve your experience by securely maintaining your authenticated session and preferences.</p>
<p class="p4">A web beacon embeds a small transparent gif image in a web page or email used to track when the page or email has been viewed. A similar device may be used where a product, service or functionality sends data to a server when a set of user-initiated events occur such as clicking a button on the website or in an email. This is similar to a cookie &ndash; it tracks your visit and the data is only read by the server that receives the data. However, it differs because it is not browser-based, may not function as an ID card and doesn&rsquo;t store any data on your computer.</p>
<p class="p4">Most Internet browsers are set up to accept cookies automatically. You can set your browser to notify you when you receive a cookie, allowing you to decide whether or not to accept it. For some web pages requiring an authorization, cookies are not optional. Users choosing not to accept cookies or similar devices will probably not be able to access those pages or their products and services.</p>
<p class="p7"><strong>1.2.</strong><span class="s3">7 </span><strong> Contacting us by Telephone</strong></p>


<p class="p4">If you communicate with us by telephone, we may monitor or record the call. This is done for reasons such as maintaining the integrity of your account, providing effective and timely service, and the improvement of First Homes Africa&rsquo;s products.</p>
<p class="p5">&nbsp;</p>
<p class="p1"><span class="s1"><strong>2. Use of your personal information</strong></span></p>
<p class="p19">We use personal information to provide you with information or services you request, to inform you about other information and services we think will be of interest to you, to facilitate your use and our operation of our website, and to improve our products and services. We don&rsquo;t forget about you once you&rsquo;ve signed&mdash;First Homes Africa provides services to propel your career and financial future throughout the life lease to own contract.</p>
<p class="p4">Here are some examples of ways in which we use your personal information:</p>
<ul class="ul1">
<li class="li10">Creating and maintaining your login information on our site</li>
<li class="li10">Contacting you regarding issues with your account</li>
<li class="li10">Allowing our loan servicing partners to process your payments</li>
<li class="li10">Invitations to First Homes Africa members events</li>
<li class="li10">Providing member services, such as career management support</li>
<li class="li13">Sending you information about new product offerings</li>
</ul>
<p class="p5">&nbsp;</p>
<p class="p4"><strong>2.1 Sharing your personal information</strong></p>
<p class="p4">We don&rsquo;t sell or rent your personal information to anyone. First Homes Africa only shares your personal information with unaffiliated third parties as permitted or required by law. We may share your personal information with our affiliate companies and as part of joint marketing campaigns with other financial companies. When First Homes Africa shares your personal information with vendors and service providers who perform functions on our behalf, we require the security and confidentiality of your information, as well as limiting their use of the information to reasonably and necessarily to carry out their work with us and comply with applicable laws and regulations. Third parties we share your information with may include, but are not limited to:</p>
<ul class="ul1">
<li class="li10">Our loan servicing partners who provide your statements, process your payments, and service your loan</li>
<li class="li10">Financial institutions as required by laws regulating loan securitization</li>
<li class="li10">Credit reporting agencies</li>
<li class="li10">Direct marketing service providers</li>
<li class="li10">Auditors and examiners</li>
<li class="li10">Law enforcement, regulators and other government bodies</li>
<li class="li13">Our regulators and other relevant government agencies</li>
</ul>
<p class="p4">Please note that, if you are a current borrower, you may not control certain information associated with your loan, that you have already agreed to provide to certain investors as described in your loan agreement.</p>
<p class="p4">Finally, personal information may be disclosed or transferred as part of, or during negotiations of, a merger, consolidation, sale of our assets, as well as equity financing, acquisition, strategic alliance or in any other situation where personal information may be transferred as one of the business assets of First Homes Africa.</p>
<p class="p5">&nbsp;</p>
<p class="p1"><span class="s1"><strong>3. Protecting your personal information</strong></span></p>
<p class="p4"><strong>3.1. What we do to protect your personal information</strong></p>
<p class="p4">First Homes Africa takes the privacy and security of its members personal information seriously. We maintain administrative, technical and physical safeguards designed to protect your information&rsquo;s security, confidentiality and integrity.</p>
<p class="p4">We protect personal information you provide online in connection with registering yourself as a user of our website. Access to your own personal information is available through a unique user ID and password selected by you. This password is encrypted while transmitted from your browser to our servers and while stored on our systems. To protect the security of your personal information, never share your password to anyone. Please notify us immediately if you believe your password has been compromised.</p>
<p class="p4">Whenever we save your personal information, it&rsquo;s stored on servers and in facilities that only selected First Homes Africa personnel and our contractors have access to. We encrypt all data that you submit through First Homes Africa&rsquo;s website during transmission using Transport Layer Security (TLS) in order to prevent unauthorized parties from viewing such information. Remember &ndash; all information you submit to us by email is not secure, so please do not send sensitive information in any email to First Homes Africa. We never request that you submit sensitive or personal information over email, so please report any such requests to us by sending an email to <span class="s5">support@firsthomesafrica.com</span></p>
<p class="p4"><strong>3.2. Steps you can take to help us keep your information safe</strong></p>
<p class="p4">There are many steps you can take to help us keep your information safe. First and foremost, choose complex, independent passwords for each website and service you maintain an account with. This helps keep any breach of any of your accounts isolated to one service. Also, don&rsquo;t use anything related to your birthday, address, phone number, PIN number or any other easily guessable information in your password.</p>
<p class="p4">First Homes Africa urges your caution when using a public computers or networks, like at a coffee shop or library. To best protect your personal information and login information, don&rsquo;t use such computers to access your sensitive accounts, and if you must do, ensure that you logout of your account entirely.</p>
<p class="p4">When either you or we update information in your account, such as the status of a registration, we typically send you notice of these changes via email or text message. In recent years, individuals, businesses and even governments have seen a rise in &ldquo;phishing&rdquo; attacks. Phishing occurs when someone attempts to obtain your password or other sensitive information. Scammers often do this by impersonating a trusted user, or offering a compelling reason to open a malicious email attachment, click on a link or give over information. We never ask for your sensitive personal information, such as password, over email or other unsecure methods or through any site not under the First Homes Africa.com domain. Please notify us<span class="Apple-converted-space">&nbsp; </span>at <span class="s5">support</span><span class="s2">@firsthomesafrica.com</span> if you ever receive suspicious correspondence from us.</p>
<p class="p1"><span class="s1"><strong>4. Your choices regarding your personal information</strong></span></p>
<p class="p4">We want you to be aware of the choices you have in limiting the sharing of your personal information. Please refer to the below table to review your options in sharing with us:</p>
<table class="t1" cellspacing="0" cellpadding="0">
<tbody>
<tr>
<td class="td1" valign="middle">
<p class="p20"><strong>Reasons we share your information</strong></p>
</td>
<td class="td1" valign="middle">
<p class="p20"><strong>Does First Homes Africa share?</strong></p>
</td>
<td class="td1" valign="middle">
<p class="p20"><strong>Can you limit this sharing?</strong></p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For our everyday business purposes&mdash;such as to process your transactions, maintain your account(s), respond to court orders and legal investigations, or report to credit bureaus</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">No</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For our marketing purposes&mdash;to offer our products and services to you</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">No</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For joint marketing with other financial companies</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">No</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For our affiliates&rsquo; everyday business purposes&mdash;information about your transactions and experiences</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">No</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For our affiliates&rsquo; everyday business purposes&mdash;information about your creditworthiness</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For our affiliates to market to you</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
<td class="td1" valign="middle">
<p class="p20">Yes</p>
</td>
</tr>
<tr>
<td class="td1" valign="middle">
<p class="p21">For non-affiliates to market to you</p>
</td>
<td class="td1" valign="middle">
<p class="p20">No</p>
</td>
<td class="td1" valign="middle">
<p class="p20">We don&rsquo;t share</p>
</td>
</tr>
</tbody>
</table>

<p class="p24">If you are a new customer, we can begin sharing your information 45 days from the date we sent you the privacy notice. When you are no longer our customer, we may continue to share your information as described in this policy. However, you can contact us at any time to limit our sharing and to restrict online marketing and direct mail.</p>
<p class="p25">&nbsp;</p>
<p class="p24">You control the information you share in your profile on our community website. You understand that if you choose to share your information, your profile may be generally available on our website.</p>
<p class="p4">When you post information to our community website, you should also be aware that anything you post on our users&rsquo; forum can be used, read, or collected by other users of the online community services and could be used to send you unsolicited messages.</p>
<p class="p4">Our website contains links to other sites that may collect your personal information. For example, First Homes Africa has relationships with various service providers whose services are available to you from our website, from other websites that are linked to our website, or in connection with our services. These partners offer services we think our customers may find useful or will improve our services. Those providers may require you to provide personal information to use their services. Personal information collected on other websites is not covered by this Privacy Policy. Unless stated otherwise, the policies of those partners will govern the collection, use, disclosure and security of your personal information.</p>
<p class="p4">We don&rsquo;t respond to &ldquo;do not track&rdquo; signals or similar mechanisms &ndash; where a visitor to First Homes Africa&rsquo;s website requests that we disable the collection of information about the visitor&rsquo;s online activities, including navigation around our website and the service. We may also permit third parties to collect aggregate usage information on our website, and they may also not respond to &ldquo;do not track&rdquo; signals.</p>
<p class="p19"><strong>4.1. Changing and Updating Your Personal Information</strong></p>
<p class="p4">We want to provide you with the resources you need to keep your information with us correct and up-to-date. You can access and maintain your personal and personally identifiable information by logging into your account and going to <strong>&ldquo;</strong><span class="s2"><strong>Account</strong></span><strong>&rdquo;</strong>. This section of the site is password-protected to safeguard your information. As a registered user, you can update your password, physical address, phone numbers, education and employment information at any time on the website. If you need to change any other information in your profile, please contact our customer service hotline.</p>
<p class="p1"><span class="s1"><strong>5. Complaints &amp; Concerns</strong></span></p>
<p class="p4">First Homes Africa takes your privacy seriously, and maintains a process to respond to your concerns regarding violations of this Policy. If you believe that we haven&rsquo;t complied with this policy in regard to your personal information or have questions regarding your privacy with us, please contact us at <span class="s5">support</span><span class="s6">@firsthomesafrica.com</span><span class="s5">. </span> If you are reporting non-compliance with this Privacy Policy, please describe the issue in as much detail as possible without disclosing any sensitive information about you or third parties. We will respond to your request by email within 7-10 business days</p>
<p class="p22">&nbsp;</p>
<p class="p23">&nbsp;</p>
          </p>
        </div>
      </div>
      <ScrollUpButton
        ToggledStyle={{ bottom: 100 }}
        style={{ borderColor: "#174a41" }}
      />
    </div>
  );
};

export default Privacy;
