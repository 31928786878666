import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import greenmail from "../../assets/greenmail.svg";
import cheveron from "../../assets/cheveron-right.svg";
import close from "../../assets/exit2.svg";
import { useDispatch } from "redux-react-hook";
import { sendMail } from "../../store/actions/auth";

const FormalComplaint = () => {
  const [modal, setModal] = useState(false);
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  const onchange = e => {
    e.preventDefault();
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  return (
    <div className={styles.formal}>
      <Nav showBackground />
      <div className={styles.main}>
        <div className={styles.header}>
          <div className={styles.top}>Feedback & Complaints</div>
        </div>
        <div className={styles.body}>
          <div>
            <div className={styles.sub}>It's all about you</div>
            <p>
              <p>
                As a customer centric company, we encourage both negative and
                positive feedback as a means of adapting and improving our
                product offering. This is because we recognise that you are the
                reason we are in business.
              </p>
              <p>
                To demonstrate this commitment, all emails sent using the
                complaint mail is delivered to the CEO for prompt action.
              </p>
              <p>
                In order to ensure that all mails received using the complaints
                handle receive the necessary attention, we ask that you only use
                this email for feedback and complaints.
              </p>
              <p>
                For product information and general enquiries please visit the{" "}
                <Link to="/contact">
                  <b>CONTACT US</b>
                </Link>{" "}
                page on the website or email{" "}
                <a href="mailto:support@firsthomesafrica.com">
                  support@firsthomesafrica.com
                </a>
              </p>
            </p>
          </div>
          <div className={styles.side}>
            <div className={styles.box}>
              <div className={styles.top}>
                <img src={greenmail} alt="greenmail" /> Drop your complaint
              </div>
              <div className={styles.under}>
                We aim to respond to your complaint within 2 working days.
                {/* <Link to="/complaint/contact">
                  Email us <span />
                </Link> */}
              </div>
              <Link
                to="#"
                className={styles.foot}
                onClick={() => setModal(true)}
              >
                Click here <img src={cheveron} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.body}>
            <div className={styles.close} onClick={() => setModal(false)}>
              <img src={close} alt="" />
            </div>
            <div className={styles.title}>Fill up the form</div>
            <div className={styles.details}>
              <form
                onSubmit={e => {
                  e.preventDefault();
                  dispatch(
                    sendMail(details, "complaint", () => {
                      setDetails({});
                      setModal(false);
                    })
                  );
                }}
              >
                <div className={styles.split}>
                  <div className={styles.group}>
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First name"
                      onChange={onchange}
                      value={details.firstname || ""}
                    />
                  </div>

                  <div className={styles.group}>
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last name"
                      onChange={onchange}
                      value={details.lastname || ""}
                    />
                  </div>
                </div>

                <div className={styles.split}>
                  <div className={styles.group}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={onchange}
                      value={details.email || ""}
                    />
                  </div>

                  <div className={styles.group}>
                    <input
                      type="phone"
                      name="phoneNumber"
                      placeholder="Phone number"
                      onChange={onchange}
                      value={details.phoneNumber || ""}
                    />
                  </div>
                </div>

                <div className={styles.group}>
                  <textarea
                    name="message"
                    cols=""
                    rows="10"
                    placeholder="Message"
                    onChange={onchange}
                    value={details.message || ""}
                  />
                </div>
                <div className={styles.btn}>
                  <button type="submit">SUBMIT</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default FormalComplaint;
