import React, { useState } from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import lady from "../../assets/girl.jpg";
import { Link } from "react-router-dom";
import DatePicker from "react-date-picker";

const SignupInfluencer = props => {
  const [date, setdate] = useState(null);
  const [show, setshow] = useState(true);

  return (
    <div className={styles.signup}>
      <Nav onlyHome />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${lady}) center `,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div>
            <div className={styles.text}>Welcome to First Homes!</div>
            <div className={styles.text2}>
              Get your campaign live and start inviting targeted users in
              minutes.{" "}
            </div>
            <div className={styles.text3}>
              By accessing and utilizing this platform you agree to be bound by
              the First Homes <Link to="#">Privacy Policy</Link> and{" "}
              <Link to="#">Terms of Use.</Link>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head}>
              Already have an account? <Link to="/signin">Sign in</Link>
            </div>
            <form>
              <input type="text" name="firstname" placeholder="First name" />
              <input type="text" name="lastname" placeholder="Last name" />
              {show && (
                <input
                  onFocus={() => setshow(false)}
                  readOnly
                  value={
                    (date && `${new Date(date).toDateString()}`) ||
                    "Date of birth"
                  }
                />
              )}
              {!show && (
                <DatePicker
                  onBlur={() => {
                    setTimeout(() => {
                      setshow(true);
                    }, 1000);
                  }}
                  onChange={data => setdate(data)}
                  value={date}
                  format="MMMM d y"
                  className={styles.datepick}
                  monthPlaceholder=".." //"Date of Birth"
                />
              )}
              {/* <input type="text" name="phone" placeholder="Phone number" /> */}
              <input type="email" name="email" placeholder="Email address" />
              <input type="password" name="password" placeholder="Password" />
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
              />
              <input type="submit" value="SIGN UP" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupInfluencer;
