import React, { useState } from "react";

import Nav from "../../components/Nav";
import styles from "./index.module.scss";
import banner1 from "../../assets/signinbanner_dark.jpeg";
import spinner from "../../assets/spinner.svg";
import { Link } from "react-router-dom";
import { useDispatch } from "redux-react-hook";
import { loginUser } from "../../store/actions/auth";
import { useMappedState } from "redux-react-hook";
import includesAll from "../../utils/includesAll";
import { setNotify } from "../../store/actions/notify";
import eye from "../../assets/eye.svg";
import eyecl from "../../assets/eyecl.svg";

const Signin = ({ history }) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const user = useMappedState(state => state.auth);
  const [show, setShow] = useState(false);

  const onChange = e => {
    setDetails({
      ...details,
      [e.target.name]: e.target.value
    });
  };
  const submit = e => {
    e.preventDefault();
    if (!includesAll(Object.keys(details), ["email", "password"])) {
      return dispatch(
        setNotify({
          type: "error",
          title: "All fields required",
          body: "Insert all fields correctly"
        })
      );
    }

    dispatch(loginUser(details, history));
  };
  return (
    <div className={styles.signin}>
      <Nav onlyHome light />
      <div className={styles.main}>
        <div
          className={styles.left}
          style={{
            background: `url(${banner1}) bottom left`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className={styles.text}>
            Life is full of uncertainties, owning a home shouldn’t be one.
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.formGroup}>
            <div className={styles.head1}>Welcome back!</div>
            <div className={styles.head2}>
              Want to join the First Homes team?{" "}
              <Link to="/signup">Sign up</Link>
            </div>

            <form onSubmit={submit} autoComplete="off">
              <input
                autoComplete="new-password"
                type="email"
                name="email"
                onChange={onChange}
                placeholder="Email"
              />
              <div className={styles.passInput}>
                <input
                  autoComplete="new-password"
                  name="password"
                  onChange={onChange}
                  placeholder="Password"
                  type={show ? "text" : "password"}
                />
                <img
                  alt="show password"
                  src={show ? eye : eyecl}
                  className={styles.inbtn}
                  onClick={() => setShow(!show)}
                />
              </div>
              <button
                type="submit"
                className={styles.submit}
                value={spinner}
                disabled={user.loading}
              >
                {user.loading ? (
                  <img src={spinner} alt="" />
                ) : (
                  <span>SIGN IN</span>
                )}
              </button>
              <Link to="/forgot-password" className={styles.forgot}>
                FORGOT PASSWORD?
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
