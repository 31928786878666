import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import Nav from "../../components/Nav";
import Footer from "../../components/Footer";
import cheveron from "../../assets/cheveron-right.svg";
import call from "../../assets/call.svg";
import emergency from "../../assets/emergency.svg";
import greenmail from "../../assets/greenmail.svg";
import search from "../../assets/searchsm.svg";

const FormalComplaintContact = () => {
  return (
    <div className={styles.formal}>
      <Nav showBackground />
      <div className={styles.main}>
        <div className={styles.body}>
          <div className={styles.sub}> Your contact options</div>
          <div className={styles.boxes}>
            <div className={styles.box}>
              <div className={styles.top}>
                {" "}
                <img src={greenmail} alt="greenmail" /> Email us
              </div>
              <div className={styles.under}>
                We aim to respond to every email within 3 working days.
              </div>
              <Link to="" className={styles.foot}>
                <img src={cheveron} alt="" /> Email us
              </Link>
            </div>

            <div className={styles.box}>
              <div className={styles.top}>
                <img src={emergency} alt="emergency" /> Emergency
              </div>
              <div className={styles.under}>
                Our teams are on hand when you need us, especially in the event
                of an emergency. <br />
                <Link to="/complaint/contact">
                  CONTACT US <span />
                </Link>
              </div>
            </div>

            <div className={styles.box}>
              <div className={styles.top}>
                <img src={call} alt="call" /> Call us
              </div>
              <div className={styles.under}>
                Enter your postcode to see the number for your nearest
                First Homes office
                <div className={styles.form}>
                  <input placeholder="Enter a location" />
                  <button>
                    <img src={search} alt="search" />{" "}
                  </button>
                </div>
              </div>
              <Link to="" className={styles.foot}>
                <img src={cheveron} alt="" /> See all office locations
              </Link>
            </div>
          </div>

          <div className={styles.other}>
            We hope you won't ever have to, but if you would like to make a
            formal complaint, please use the email link above and select 'Formal
            complaint' from the list of options on the form. You can also find
            out more about our formal complaints process.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FormalComplaintContact;
