import { authUserSuccess, refreshUser, getFiles, getNOK } from "../store/actions/auth";
import Axios from "axios";
import {
  getStates,
  getHouseTypes,
  fetchCalculation,
  generateDownPayement,
  getDownPayementStatus
} from "../store/actions/onboard";
import {
  getContratual,
  getContribution,
  getDeposits,
  getWithdrawal,
  getBanks,
  getVoluntary
} from "../store/actions/wallet";
import { allCards } from "../store/actions/card";

const setUser = store => {
  Axios.defaults.baseURL = "https://staging.firsthomesafrica.com/api/v2/";
  let user = null;
  const rawUser = localStorage.getItem("user");
  if(rawUser){
    user = JSON.parse(rawUser);
  }

  if(user && user.hasPassword){
    const lastVisited = localStorage.getItem("lastVisited");
    if (!lastVisited ) {
      try {
        localStorage.clear();
      } catch (error) {}
    }

    if (new Date() - new Date(lastVisited) > 10000) {
      try {
        localStorage.clear();
      } catch (error) {}
    }
  }
 
  if (user) {
    const token = localStorage.getItem("token");
    if (localStorage.getItem("user")) {
      Axios.defaults.headers.common["x-access-token"] = token;
      

      store.dispatch(authUserSuccess(user));
      store.dispatch(refreshUser());
      store.dispatch(getNOK());
      store.dispatch(getStates());
      store.dispatch(getHouseTypes());
      store.dispatch(getContratual());
      store.dispatch(getVoluntary());
      store.dispatch(getContribution());
      store.dispatch(getDeposits());
      store.dispatch(allCards());
      store.dispatch(getFiles());
      store.dispatch(getWithdrawal());
      store.dispatch(fetchCalculation());
      store.dispatch(generateDownPayement());
      store.dispatch(getBanks());
      store.dispatch(getDownPayementStatus());
    }
  }
};

export default setUser;
