import React, { useState } from 'react';
import navLogo from '../../assets/navLogo.svg';
import navDark from '../../assets/navLogoDark.svg';
import menu from '../../assets/menu.svg';
import menugray from '../../assets/menu.svg';
import menuWhite from '../../assets/menu1.svg';
import close from '../../assets/xgray.svg';
import bell from '../../assets/bell.svg';
import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { useMappedState, useDispatch } from 'redux-react-hook';
import { logoutUser } from '../../store/actions/auth';

const Nav = ({ light, onlyHome, showBackground }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const user = useMappedState(({ auth }) => auth.currentUser);

  return (
    <div className={`${styles.nav} ${showBackground ? styles.background : ''}`}>
      <div className={styles.logo}>
        <NavLink to={user ? '/dashboard' : '/'}>
          <img src={light ? navDark : navLogo} alt='logo' />
        </NavLink>
      </div>
      {!onlyHome && (
        <div className={styles.mobilelinks}>
          {!user && (
            <div className={styles.menu} onClick={() => setOpen(true)}>
              <img src={light ? menuWhite : menugray} alt='' />
            </div>
          )}
          {open && (
            <div className={styles.mobileMenu}>
              <div
                className={styles.head}
                activeClassName={styles.active}
                onClick={() => setOpen(false)}
              >
                <img src={close} alt='' />
              </div>
              <div className={styles.body}>
                <NavLink
                  activeClassName={styles.active}
                  exact
                  onClick={() => setOpen(false)}
                  to='/'
                >
                  START HERE
                </NavLink>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/yourhome'
                >
                  YOUR HOME
                </NavLink>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/about'
                >
                  OUR STORY
                </NavLink>
                {/* <a
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://bitrix24public.com/firsthomesafrica.bitrix24.com/form/1_contact_info/z1lpvl/' //'/contact'
                >
                  CONTACT US{' '}
                </a> */}
                {/* <NavLink activeClassName = { styles.active }
                        onClick = {
                            () => setOpen(false)
                        }
                        to = '/blog'>
                        BLOG </NavLink>  */}
                {/* <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/careers'
                >
                  CAREERS
                </NavLink> */}
                {/* <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/faqs'
                >
                  FAQs
                </NavLink> */}
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/gallery'
                >
                  GALLERY
                </NavLink>
                {user ? (
                  <>
                    <NavLink
                      activeClassName={styles.active}
                      onClick={() => setOpen(false)}
                      to='/dashboard'
                    >
                      DASHBOARD
                    </NavLink>
                    {user.hasPassword && (
                      <NavLink
                        activeClassName={styles.active}
                        to='#'
                        onClick={() => {
                          dispatch(logoutUser());
                          return setOpen(false);
                        }}
                      >
                        LOGOUT
                      </NavLink>
                    )}
                  </>
                ) : (
                  <>
                    <NavLink
                      activeClassName={styles.active}
                      onClick={() => setOpen(false)}
                      to='/signin'
                    >
                      SIGN IN
                    </NavLink>
                    <NavLink
                      activeClassName={styles.active}
                      to='/signup'
                      onClick={() => setOpen(false)}
                    >
                      TRY IT NOW
                    </NavLink>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!onlyHome && (
        <div className={styles.links}>
          <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            exact
            to='/'
          >
            START HERE
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            to='/yourhome'
          >
            YOUR HOME
          </NavLink>
          <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            to='/about'
          >
            OUR STORY
          </NavLink>

          {/* <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            to='/faqs'
          >
            FAQs
          </NavLink> */}
          <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            to='/gallery'
          >
            GALLERY
          </NavLink>
          {/* <NavLink
            activeClassName={styles.active}
            onClick={() => setOpen(false)}
            to='/careers'
          >
            CAREERS
          </NavLink> */}
          {user ? (
            <>
              <NavLink
                to='/dashboard'
                className={`${light && styles.light}`}
                activeClassName={styles.active}
              >
                DASHBOARD
              </NavLink>
              {user.hasPassword && (
                <NavLink
                  to='#'
                  className={`${light && styles.light}`}
                  activeClassName={styles.active}
                  onClick={() => dispatch(logoutUser())}
                >
                  LOG OUT
                </NavLink>
              )}
            </>
          ) : (
            <>
              <NavLink
                to='/signin'
                className={`${light && styles.light}`}
                activeClassName={styles.active}
              >
                SIGN IN
              </NavLink>
              <NavLink
                to='/signup'
                className={`${light && styles.light}`}
                activeClassName={styles.active}
              >
                TRY IT NOW {/* <i className="lni-chevron-down"/> */}
                {open && (
                  <div className={styles.drop}>
                    <NavLink to='/signup'> USER </NavLink>
                    <NavLink to='/signup/ influencer '> INFLUENCER </NavLink>
                  </div>
                )}
              </NavLink>
            </>
          )}
        </div>
      )}
      {showBackground && user && (
        <div className={styles.dashmobile}>
          <div className={styles.menu} onClick={() => setOpen(true)}>
            <img src={menu} alt='' />
          </div>
          {open && (
            <div className={styles.mobileMenu}>
              <div
                className={styles.head}
                activeClassName={styles.active}
                onClick={() => setOpen(false)}
              >
                <img src={close} alt='' />
              </div>
              <div className={styles.body}>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  exact
                  to='/dashboard'
                >
                  Home
                </NavLink>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/dashboard/vault'
                >
                  Vault
                </NavLink>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/dashboard/save-now'
                >
                  Save Now
                </NavLink>
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/dashboard/account'
                >
                  Account
                </NavLink>
                {/* <NavLink activeClassName = { styles.active }
                        onClick = {
                            () => setOpen(false)
                        }
                        to = '/dashboard/blockwise'>
                        BlockWise </NavLink> */}
                <NavLink
                  activeClassName={styles.active}
                  onClick={() => setOpen(false)}
                  to='/dashboard/marketplace'
                >
                  MarketPlace
                </NavLink>
                {user && user.hasPassword && (
                  <NavLink
                    activeClassName={styles.active}
                    to='#'
                    onClick={() => {
                      dispatch(logoutUser());
                      return setOpen(false);
                    }}
                  >
                    Log Out
                  </NavLink>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {showBackground && user && (
        <div className={styles.dashlinks}>
          <NavLink to='#'>
            <img src={bell} alt='bell icon' />
          </NavLink>
          <NavLink to='#' onClick={() => setOpen(!open)}>
            {user && `${user.firstname} ${user.lastname}`}
            <i className='lni-chevron-down' />
            {open && (
              <div className={styles.drop}>
                <NavLink to='/dashboard/account'> Profile </NavLink>
                {user.hasPassword && (
                  <NavLink to='#' onClick={() => dispatch(logoutUser())}>
                    Sign out
                  </NavLink>
                )}
              </div>
            )}
          </NavLink>
        </div>
      )}
    </div>
  );
};
export default Nav;
